import React from 'react';
import $ from "jquery"
import { CheckAuth } from '../CheckAuth';
import { Link, useNavigate } from 'react-router-dom';
import { URL } from '../base/BaseUrl';
import { CheckSeller } from '../CheckSellerAuth';


export const SellerLayout = (props:any) => {
    const navigate = useNavigate();
    
    let user:any =localStorage.getItem("profile")|| ""
    if(user)
    {
        user= JSON.parse(user);
    }

     const LogoutUser = (e:any) =>{
        e.preventDefault();
        
        localStorage.setItem("profile","");
         navigate("/signin-user");
     }

        $(".sidebar-dropdown > a").click(function() {
      $(".sidebar-submenu").slideUp(200);
      if (
        $(this)
          .parent()
          .hasClass("active")
      ) {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .parent()
          .removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this)
          .next(".sidebar-submenu")
          .slideDown(200);
        $(this)
          .parent()
          .addClass("active");
      }
    });
    
    $("#close-sidebar").click(function() {
      $(".page-wrapper").removeClass("toggled");
    });
    $("#show-sidebar").click(function() {
      $(".page-wrapper").addClass("toggled");
    });
    
    

  return (
      <>
      <CheckAuth/>
      <CheckSeller/>
   <div className="page-wrapper chiller-theme toggled">
  <a id="show-sidebar" className="btn btn-sm btn-dark" href="#">
    <i className="fas fa-bars"></i>
  </a>
  <nav id="sidebar" className="sidebar-wrapper">
    <div className="sidebar-content">
      <div className="sidebar-brand">
        <a href="/" target="_blank">Event Incubator </a>
        <div id="close-sidebar">
          <i className="fas fa-times"></i>
        </div>
      </div>
      <div className="sidebar-header">
        <div className="user-pic">
          <img className="img-responsive img-rounded" src={URL+user?.data?.avatar}
            alt="User picture"/>
        </div>
        <div className="user-info">
          <span className="user-name">{ user?.data?.first_name+" "+user?.data?.last_name }
          </span>
          <span className="user-role"></span>
          <span className="user-status">
            <i className="fa fa-circle"></i>
            <span>Online</span>
          </span>
        </div>
      </div>
     
     

      <div className="sidebar-menu">
        <ul>
          <li>
            <Link to="/seller/profile/update">
              <i className="fa fa-book"></i>
              <span>Profile Update </span>
              <span className="badge badge-pill badge-primary"></span>
            </Link>
          </li>

          <li>
            <Link to="/seller/products">
              <i className="fa fa-book"></i>
              <span>Products </span>
              <span className="badge badge-pill badge-primary"></span>
            </Link>
          </li>

          <li>
            <Link to="/seller/selled-products">
              <i className="fa fa-book"></i>
              <span>Selled Products </span>
              <span className="badge badge-pill badge-primary"></span>
            </Link>
          </li>
        
        </ul>
      </div>

    </div>
   

    <div className="sidebar-footer">
       {/*-- <a href="">
        <i className="fa fa-bell"></i>
        <span className="badge badge-pill badge-warning notification">3</span>
      </a>
      <a href="#">
        <i className="fa fa-envelope"></i>
        <span className="badge badge-pill badge-success notification">7</span>
      </a>
      <a href="#">
        <i className="fa fa-cog"></i>
        <span className="badge-sonar"></span>
  </a>
  */}
  
      <a  href="" onClick={e=>LogoutUser(e)}>
        <i className="fa fa-power-off"></i>
      </a>
    </div>
  </nav>

  <main className="page-content">
    <div className="container-fluid">
        {props.children} 
    </div>
  </main>
    </div>

    </>
  )
};
