import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { SellerLayout } from '../components/layouts/SellerLayout';
import { DashBoard } from '../pages/Seller/DashBoard';
import { CreateProduct } from '../pages/Seller/Product/CreateProduct';
import { EditProduct } from '../pages/Seller/Product/EditProduct';
import { ListProduct } from '../pages/Seller/Product/ListProduct';
import { SelledProduct } from '../pages/Seller/Product/SelledProduct';
import { ProfileUpdate } from '../pages/Seller/ProfileUpdate';

export const SellerRoute = () => {
  return (
    <SellerLayout>
    <Routes>
        <Route  path="/"  element={<DashBoard/>}/>
        <Route path="/profile/update"  element={<ProfileUpdate/>}/>
        <Route path="/products/"  element={<ListProduct/>}/>
        <Route path="/product/create"  element={<CreateProduct/>}/>
        <Route path='/product/:id' element={<EditProduct/>}/>
        <Route path='/selled-products' element={<SelledProduct/>}/>
    </Routes>
    </SellerLayout>
  )
};
