import React from "react";

export interface Currency{
    currencyName: string,
    setCurrency:any,
    currencies: Array<{}>,
    symbol:String,
    exchangeRate:number,
}

const initialCurrency = {
    currencyName :"",
    currencies: [],
    setCurrency: null,
    symbol : "",
    exchangeRate :0,
}
export const CurrenciesContext= React.createContext<Currency>(initialCurrency);

