import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBrandsService } from '../../../api/Brand/BrandService';
import { getCategoriesService } from '../../../api/category/CategoeryService';
import { getProductTypesService } from '../../../api/product-type/ProductType';
import { showSellerProductsService, storeProductService, updateProductService } from '../../../api/products/ProductCrud';
import { ShowNotification } from '../../../components/Notification/ShowNotification';
import i18n from '../../../config/i18n';

export const EditProduct = () => {
  const navigate = useNavigate();
  const {id } = useParams();
  const [product, setProduct] = useState<any>();
  const [cats, setCats ] = useState([]);
  const [brands, setBrands ] = useState([]);
  const [types, setTypes] = useState([]);
  const [errName , setErrName] = useState<string>("");
  const [errDescription , setErrDescription] = useState<string>("");
  const [errPhoto , setErrPhoto] = useState<string>("");

   

   useEffect(()=>{
    fetchProduct()
    fetchCategories();
    fetchBrands();
    fetchProductTypes();
   },[i18n.language]);

   const fetchProduct = async () =>{
      const res = await showSellerProductsService(id);
      if(res?.success===true)
      {
         setProduct(res.data);
      }
   }

    const  fetchCategories =  async () =>{
         const res = await getCategoriesService();
         if(res?.success===true)
         {
            setCats(res.data);
         }
    }
    const  fetchBrands =  async () =>{
      const res = await getBrandsService();
      if(res?.success===true)
      {
         setBrands(res.data);
          }
    }
    const  fetchProductTypes =  async () =>{
      const res = await getProductTypesService();
      if(res?.success===true)
      {
        setTypes(res.data);
      }
    }

  const handelProductUpdate = (e:any) =>{
      e.preventDefault();
      const data = new FormData(e.target);
       updateProductService(data,id).then((res)=>{
        console.log(res);
          if(res?.success === true)
          {
              ShowNotification("Product updated success","success");
              navigate("/seller/products");
          }
          else{
              setErrName(res.data.errors.en_name);
              setErrDescription(res.data.errors.en_description);
              setErrPhoto(res.data.errors.photos)
          }
      });
  }
  return (
       <>
         <div className="container" >
            <div className="row">
            <form className='p-5 bg-white profile-update' encType='multipart/form-data' onSubmit={handelProductUpdate} style={{boxShadow:"5px 5px 5px #ffffff"}}>
                 <h2 className='text-center pb-3'>Product Update</h2>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label > Name</label>
                      <input type="text" name="name" value={product?.name} className="form-control"/>
                      <div className='invalid-feedback'>
                          <p>{errName}</p>
                      </div>
                    </div>
                    
                    <div className="form-group col-md-6">
                      <label>Description</label>
                       <textarea className='form-control' name="description" rows={3}>
                        {product?.description}
                       </textarea>
                      <div className='invalid-feedback'>
                          <p>{errDescription}</p>
                      </div>
                    </div>

                    
                    <div className="form-group col-md-6">
                        <label>Category Name</label>
                        <select name="cat_id" value={product?.cat_id} className='form-control'>
                              {cats.map((cat:any)=>(
                                  <option  value={cat.id}>{cat.name}</option>
                               ))}
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                      <label >Brand </label>
                       <select name="brand_id" value={product?.brand_id} className='form-control'>
                           {brands.map((brand:any)=>(
                              <option value={brand.id}>{brand.name}</option>
                           ))}
                            
                         </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Price</label>
                      <input type="number" min={1} value={product?.price} className="form-control"  name="price"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label> Offer Price</label>
                      <input type="number" min={0} value={product?.offer_price} className="form-control"  name="offer_price"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Product Type</label>
                       <select name="product_type" value={product?.product_type} className='form-control'>
                         {types.map((type:any)=>(
                             <option value={type.id}  className='form-control'>{type.name}</option>
                         ))}
                          
                       </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Photo</label>
                      <input type="file" className="form-control"  name="photos[]"/>
                      <div className='invalid-feedback'>
                          <p>{errPhoto}</p>
                      </div>
                    </div>
                   
                  </div>
                  <div className="form-group">
                    <label >Product Status</label>
                      <select name="status" value={product?.status} className='form-control'>
                         <option value="1">Enable</option>
                         <option value="0">Disable</option>
                      </select>
                  </div>
     
                  <button type="submit" className="btn btn-primary">Update </button>
                </form>
           </div>
          </div>
      </>
  )
};
