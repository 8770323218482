import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getCategoriesService } from '../../api/category/CategoeryService';
import CreatableSelect from 'react-select/creatable';
import i18n from '../../config/i18n';
import { useTranslation } from 'react-i18next';

export const ProductSearchBar = () => {
    const { t }  = useTranslation()
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    
    const [formData, setFormData] = useState<{categoryId:number | null, productName: string}>({ categoryId:null, productName:"" });
    const handleSubmit = (e:any) => {
        e.preventDefault();
       navigate("/searching-products", {state:{
            formData
        }})
    }
    useEffect(()=>{
        fetchCategoies();
      },[i18n.language])
    
       
    const catData:any =[];

    {categories.map((cat:any)=>
         catData.push({value:cat.id, label: cat.name })
    )}

    const setProductName = (name:string) =>{
 
        setFormData({...formData,productName:name});
      }
  
      const  fetchCategoies = async () =>{
        const res = await getCategoriesService();
        if(res?.success===true)
        {
          setCategories(res.data);
        }
      }

    const setCategoryId = (id:number) => {
        setFormData({...formData,categoryId:id});
    }
  return (
                <div className="searchbar">
                       <form  onSubmit={handleSubmit}>
                        <div className="input-group product-search">
                                      
                                       <CreatableSelect
                                          isClearable
                                          options={catData}
                                          onChange={(data:any) => setCategoryId(data.value)}
                                          onInputChange = {(data:any) => setCategoryId(data.value)}
                                        />
                                
                            
                            <input type="text" name="name" value={formData.productName}  className="form-control" onChange={e=>setProductName(e.target.value)} placeholder={t("search_items") }/>
                            <div className="input-group-append">
                                <button type="submit" ><i className="fas fa-search"></i></button>
                              </div>
                        </div>
                       </form>
                    </div>
  )
}
