import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sellerRegistrationService } from '../../api/auth/AuthService';
import { ShowNotification } from '../../components/Notification/ShowNotification';

export const SignUpSeller = () => {
    const { t } = useTranslation();
    const [errFirstName, setErrFirstName ] = useState<string>("");
    const [errLastName, setErrLastName ] = useState<string>("");
    const [errEmail, setErrEmail] = useState<string>("");
    const [errPassword, setErrPassword] = useState<string>("");

    const handleSignUp = (event:any) => {
        event.preventDefault();
        const fd = new FormData(event.target);

         sellerRegistrationService(fd).then((res)=>{
              if(res?.success === true)
              {
                  ShowNotification(res.message, "success");
              }
              if(res?.status === 422)
              {
                  setErrFirstName(res.data.errors.first_name);
                  setErrLastName(res.data.errors.last_name);
                  setErrEmail(res.data.errors.email);
                  setErrPassword(res.data.errors.password);
              }
         });

    }
  return (
  <>
   <div className="container my-5">
        <div className="row justify-content-center">
            <div className="col-md-10 col-lg-8 col-sm-7">
                <div className="p-5" style={{boxShadow: "0px 5px 15px 0px #00000040",borderRadius:"10px"}}>
                    <h6 className="text-center"><b>Vendor Information</b></h6>
                    <div className="pt-2">
                        <form onSubmit={handleSignUp}>
                             <h5><b>User Information</b></h5>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="text" className="form-control" name="name"  autoComplete="name" autoFocus placeholder='Full Name'/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="text" className="form-control" name="mobile"  autoFocus placeholder='Mobile'/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="email" className="form-control" name="email"  autoComplete="email" autoFocus placeholder="Email"/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errEmail}</strong>
                                        </span>   
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="text" className="form-control" name="password"  autoFocus placeholder='Password'/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>
                            </div>
                            <h6><b>Company Information</b></h6>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="text" className="form-control" name="company_name"  autoComplete="company_name" autoFocus placeholder='Company Name'/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="file" className="form-control" name="mobile"  autoFocus placeholder='Logo'/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>
                            </div>

                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="text" className="form-control" name="license"  autoComplete="license" autoFocus placeholder="License Number"/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errEmail}</strong>
                                        </span>   
                                    </div>
                                </div>

                                <div className='col-sm-6'>
                                    <div className="form-group ">
                                        <input type="text" className="form-control" name="eastablished_year"  autoFocus placeholder='Eastablished Year'/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className="form-group ">
                                        <input type="text" className="form-control" name="address"  autoFocus placeholder='Address'/>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>

                                <div className='col-12 col-sm-6'>
                                    <div className="form-group ">
                                        <select name="country_id" className='form-control'>
                                            <option>Saudi Arabia</option>
                                            <option>Bangladesh</option>
                                        </select>
                                        <span className="invalid-feedback" role="alert">
                                            <strong >{ errFirstName}</strong>
                                        </span>   
                                    </div>
                                </div>

                               
                            </div>

                            <div className='col-sm-6'>
                                <div className="form-group  mb-0">
                                    <button type="submit" className="btn btn-primary text-white w-100">
                                        { t('sign_up') }
                                    </button> <br/>
                                </div>
                            </div>
                        
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </>)
};
