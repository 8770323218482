import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Subscribe = () => {
    const { t } = useTranslation();
    const [email, setEmail] =  useState<string>("");
  return (
  <>
    <section className="subscribe-area  r-spacing">
    <div className="container">
        <div className="row">
            <div className="col-12">
                <div className="subscriber-content">
                    <div className="row">
                        <div className="col-md-8">
                            <h4>{ t("subscribe_email")} l</h4>
                            <form action="" method="POST" id="subscribe-form" className="subscriber-form">
                            <div className="subscriber-input">                         
                                <input type="email" name="mail" required value="" className="form-control" placeholder={t("email_address")} onChange={e => setEmail(e.target.value)}/>
                            </div>
                            <div className="subscriber-btn">
                                <button type="submit">{ t("subscribe") }</button>
                            </div>  
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
  </>);
};
