import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'
import { useCart } from 'react-use-cart';
import { getCategoriesService } from '../../api/category/CategoeryService';
import i18n from '../../config/i18n';
import logo from "../../images/logo.png"
import { CurrenciesContext } from '../context/CurrencyContext';
import { ProductSearchBar } from './ProductSearchBar';


export const MobilepTopBar = () => {
    const { t } = useTranslation()
    const Navigate = useNavigate();
    const {totalUniqueItems } = useCart()
    const [categories, setCategories] = useState([]);
    const [formData, setFormData] = useState<{categoryId:number | null, productName: string}>({ categoryId:null, productName:"" });
    let user:any = localStorage.getItem("profile");
  if(user && user!= "")
  {
      user = JSON.parse(user);
  }

  useEffect(()=>{
    fetchCategoies();
  },[i18n.language])

    const setProductName = (name:string) =>{
 
        setFormData({...formData,productName:name});
      }
  
      const  fetchCategoies = async () =>{
        const res = await getCategoriesService();
        if(res?.success===true)
        {
          setCategories(res.data);
        }
      }

      const handleSubmit = (e:any) => {
        e.preventDefault();
       Navigate("/searching-products", {state:{
            formData
        }})
    }

    const setCategoryId = (id:number) => {
        setFormData({...formData,categoryId:id});
    }

    const changeLanguage = (name:string) => {
        i18n.changeLanguage(name);
        document.body.dir = i18n.dir();
     
        let arabic = document.getElementById("arabicCss");

        if(i18n.dir()==="rtl"){
          if(arabic){
             arabic.setAttribute("href","css/arabic.css?v="+new Date().getTime());
          }
        }
        else{
          if(arabic)
          arabic.setAttribute("href","");
        }
       
    }

  return (
    <section className='mobile-topbar'>
    <div className="topbar-area" >
    <div className="container">
      <div className="row">
      <div className='col-12'>
        <div className='d-flex mt-2 justify-content-between'>
            <div className="logo">
                  <a href="/">
                      <img width="80" height="30px" src={logo} alt="Site Logo"/>
                  </a>
              </div>
              <div className="help-center mt-1">
                  <i className="fas fa-headphones-alt"></i>
                  <span className="mx-1"> { t("hotline")}: </span>
                  <span className="font-sans">+966 551175959</span>
              </div>
                <div className="navbar-menus">
                      
                      <Link to="/cart" className="">
                          <i className="fas fa-cart-arrow-down text-white"></i>
                          <span className="cartItem font-sans">{totalUniqueItems}</span>
                      </Link>
                     
                </div>
          </div>
          </div>
        <div className="col-12 topbar">
          <div className="topbar-left d-flex ">
         
          </div>
          <div className="topbar-right">
            <div className="topbar-menu">
                <div className="btn-group">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span> { t("language") }</span>
                      <i className="fas fa-angle-down"></i>
                    </span>
                    
                    <div className="dropdown-menu dropdown-menu-right">
                      <button  className="dropdown-item"  onClick={e=>changeLanguage("en")} > {(i18n.language==="en")?(<i className="fas fa-check language-active"></i>):(<i className="fas fa-check"></i>)}<span>{ t("english")} </span>  </button>
                      <button  className="dropdown-item"  onClick={e=>changeLanguage("ar")}> {(i18n.language==="ar")?(<i className="fas fa-check language-active"></i>):(<i className="fas fa-check"></i>)}{ t("arabic")}  </button>
                    </div>
                </div>
                <div className="btn-group currency-dropdown">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span>{ t("currency") }</span>
                      <i className="fas fa-angle-down"></i>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right ">
                      <CurrenciesContext.Consumer>
                      {({currencies, currencyName, symbol,exchangeRate, setCurrency }) => (
                        currencies.map((currency:any, index)=>(
                          <button  className="dropdown-item" value={currency.name} onClick={e=>setCurrency(currency.name)} key={currency.id}>
                              {(currencyName===currency.name)?(<i className="fas fa-check language-active"></i>):(<i className="fas fa-check"></i>)}
                              <span>{currency?.name}</span>
                          </button>
                        ))
                      )}
                      </CurrenciesContext.Consumer>
                    </div>
                </div>
               
                {(user && user.data.user_type==3)?( <div className='btn-group'> <Link to="/client-panel" className=" w-100" >{ t("dashbaord")} </Link>  
                    </div>):""}
                 {(user && user.data.user_type==2)?(
                <div className='btn-group'> <Link to="/seller" className=" w-100" >{ t("dashbaord")} </Link> 
                 </div>
                 ):""} 

                    {(!user)?  (
                      <>
                     <div className='btn-group'> <Link to="/signin-user" className=" w-100" >{ t("signin")} </Link>  
                    </div>
                   
                <div className="btn-group">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i className="far fa-user"></i>
                        <span className="mx-1"></span> 
                        <span>{ t("signup") }</span>
                        <i className="fas fa-angle-down"></i>
                    </span>
                    
                    <div className="dropdown-menu dropdown-menu-right" >
                        <div className="dropdown-item" >
                        { user? "" :(<ul>
                            

                             <li> <Link to="signup-user"  className="btn btn-outline-secondary w-100 mt-3">{ t("user")}  </Link>  </li>
                             <li> <Link to="signup-seller"  className="btn btn-outline-secondary w-100 mt-3">{ t("supplier")}  </Link>  </li>
                           </ul>)
                                }
                        </div>
                     
                       
                    </div>
                </div>
              
                </>
                    ):""}
            </div>
          </div>
        </div>
       
      </div>
    </div>
  </div>
  <div className="navbar-area">
    <div className="container">
        <div className="row">
            <div className="col">
                <div className="navbar-content">
                  <ProductSearchBar/>
                  
                </div>
            </div>
        </div>
    </div>
  </div>
  </section>
  )
}
