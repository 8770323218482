import React, { useEffect, useState } from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Fade } from 'react-slideshow-image';
import i18n from '../../config/i18n';
import { getSlides } from '../../api/slide/Slide';
import { URL } from '../base/BaseUrl';
import slide1 from "../../images/Homepage/Slider images/slider-01.png"
import slide2 from "../../images/Homepage/Slider images/slider-02.png"
import slide3 from "../../images/Homepage/Slider images/slider-03.png"
import slide4 from "../../images/Homepage/Slider images/slider-04.png"
import slide5 from "../../images/Homepage/Slider images/slider-05.png"
import slide6 from "../../images/Homepage/Slider images/slider-06.png"

export const Slides = () => {
  /* const [sldes, setSldes] = useState<{id:number,photo:string,title:string,name:string}[]>([])
 
   useEffect(()=>{
      fetchSlides();
   },[i18n.language])

   const fetchSlides = async () => {
      const result = await getSlides();
      if(typeof(result)!=="undefined" && result.success === true)
      {
        setSldes(result.data);
      }

   }*/



 
 
  return (
    
    <div className="slide-container">
    <Fade>
      <div className="each-fade" >
        <div className="image-container">
          <img src={slide1} />
        </div>
      </div>
      <div className="each-fade" >
        <div className="image-container">
          <img src={slide2} />
        </div>
      </div>
      <div className="each-fade" >
        <div className="image-container">
          <img src={slide3} />
        </div>
      </div>
      <div className="each-fade" >
        <div className="image-container">
          <img src={slide4} />
        </div>
      </div>
      <div className="each-fade" >
        <div className="image-container">
          <img src={slide5} />
        </div>
      </div>
      <div className="each-fade" >
        <div className="image-container">
          <img src={slide6} />
        </div>
      </div>
    </Fade>
  </div>
      
  )
 
}
