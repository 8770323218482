import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from '../components/layouts/Layout';
import { About } from '../pages/About';
import { SignUpSeller } from '../pages/Auth/SignupSeller';
import { Signin } from '../pages/Auth/User/Signin';
import { SignUp } from '../pages/Auth/User/SignUp';
import { Cart } from '../pages/Cart';
import { Checkout } from '../pages/Checkout';
import { Contact } from '../pages/Contact';
import Home from '../pages/Home';
import { Laboratory } from '../pages/Laboratory';
import { Logistic } from '../pages/Logistic';
import { NumberingCoding } from '../pages/NumberingCoding';
import { Order } from '../pages/Order';
import { OurServices } from '../pages/OurServices';
import { ProductByCategory } from '../pages/ProductByCategory';
import { ProductDetails } from '../pages/ProductDetails';
import { SearchingProduct } from '../pages/SearchingProduct';
import { ShopNow } from '../pages/ShopNow';
import { TermCondition } from '../pages/TermCondition';
import { Training } from '../pages/Training';
import { Warehouse } from '../pages/Warehouse';

export const PublicRoute = () => {
  return (
    <Layout>
    <Routes>
        <Route path="/"  element={<Home/>}/>
        <Route path="/shop"  element={<ShopNow/>}/>
        <Route path="/cart"  element={<Cart/>}/>
        <Route path="/checkout"  element={<Checkout/>}/>
        <Route path="/create-order"  element={<Order/>}/>
        <Route path="/searching-products"  element={<SearchingProduct/>}/>
        <Route path="/services"  element={<OurServices/>}/>
        <Route path="/about-us"  element={<About/>}/>
        <Route path="/contact-us"  element={<Contact/>}/>
        <Route path="/products/:categoryId/:categoryName"   element={<ProductByCategory/>}/>
        <Route path="/product-details/:id/:name"   element={<ProductDetails/>}/>
        <Route path="/term-condition" element={<TermCondition/>}/>
        <Route path='/laboratory' element={<Laboratory/>}/>
        <Route path='/logistic' element={<Logistic/>}/>
        <Route path='/training' element={<Training/>}/>
        <Route path='/warehouse' element={<Warehouse/>}/>
        <Route path='/numbering-coding' element={<NumberingCoding/>}/>
        <Route path='/signin-user' element={<Signin/>}/>
        <Route path="/signup-user" element={<SignUp/>}/>
        <Route path="/signup-seller" element={<SignUpSeller/>}/>
    </Routes>
  </Layout>
  )
};
