import React, { useEffect, useState } from 'react';
import { storeContactService } from '../api/contact/ContactService';
import { getSettingsService } from '../api/Address/Setting';
import { ShowNotification } from '../components/Notification/ShowNotification';
import i18n from '../config/i18n';
import { useTranslation } from 'react-i18next';

interface SyntheticEvent {
  bubbles: boolean;
  cancelable: boolean;
  currentTarget: EventTarget;
  defaultPrevented: boolean;
  eventPhase: number;
  isTrusted: boolean;
  nativeEvent: Event;
  preventDefault(): void;
  stopPropagation(): void;
  target: EventTarget;
  timeStamp: Date;
  type: string;
}

export const Contact = () => {

  const { t } = useTranslation();
    const [formData, setFormData] = useState<{}>()
    const [ errFirstName, setErrFirstName ] = useState<string>("")
    const [ errLastName, setErrLastName ] = useState<string>("")
    const [ errEmail, setErrEmail ] = useState<string>("");
    const [errPhone, setErrPhone ] = useState<string>("");
    const [errMessage, setErrMessage ] = useState<string>("")

    const [setting, setSetting] = useState<any>();
    useEffect(()=>{
       fetchSetting();
    },[i18n.language]);

   const  fetchSetting = async () =>{
       const res = await getSettingsService();
       console.log(res);
       if(res?.success === true)
       {
           setSetting(res.data);
       }
    }

    const handleFirstName = (value:string) =>{
       setFormData({...formData,first_name:value})
    }

    const handleLastName = (value:string) =>{
      setFormData({...formData,last_name:value})
      }

      const handleEmail = (value:string) =>{
        setFormData({...formData,email:value})
    }

    const handlePhone= (value:string) =>{
      setFormData({...formData,phone:value})
    }
    const handleMesage = (value:string) =>{
      setFormData({...formData,message:value})
   }


  
    const handleContactForm =  (e:any) =>{
        e.preventDefault();
        setErrFirstName('');
        setErrLastName("");
        setErrEmail("");
        setErrPhone("");
        setErrMessage("")
         storeContactService(formData).then((res)=>{
            if(res.success === true)
            {
              ShowNotification("Message sent successfully","success");
            }
          if(res.status === 422)
          {
                setErrFirstName(res.data.message.first_name);
                setErrLastName(res.data.message.last_name); 
                setErrEmail(res.data.message.email);   
                setErrPhone(res.data.message.phone);
                setErrMessage(res.data.message.message);
          }
        });
     
    }
  return (
    <>
        <section className="title-with-bg-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                      <div className="title-with-bg">
                        <h4 className="text-center">{ t("contact_us")}</h4>
                      </div>
                    </div>
                  </div>
            </div>
        </section>
       <section className="contact-area mt-5 p-spacer">
        <div className="container">
          <div className="row">
              <div className="col-md-8">
                  <div className="contact-form">
                     
                     <form onSubmit={handleContactForm}>
                          <h6>{ t("message") }</h6>
                          <hr/>
                          <div className="msg-show"></div>
                          
                          <div className="row">
                            <div className="col-md-6 col-sm-12 form-group">
                              <input type="text" onChange={e=>handleFirstName(e.target.value)} className="form-control field-text" placeholder={ t("first_name") }/>
                              <span style={{ color: "red"}}>{errFirstName}</span>
                            </div>
                            <div className="col-md-6 col-sm-12 form-group">
                              <input type="text" onChange={e =>handleLastName(e.target.value)}  className="form-control field-text" placeholder={ t("last_name") }/>
                              <span style={{ color: "red"}}>{ errLastName}</span>
                            </div>
                            <div className="col-md-6 col-sm-12 form-group">
                              <input type="text" name="email" onChange={e => handleEmail(e.target.value)} className="form-control field-text" placeholder={ t('email') }/>
                              <span style={{ color: "red"}}>{errEmail}</span>
                            </div>
                            <div className="col-md-6 col-sm-12 form-group">
                              <input type="text"onChange={e=>{handlePhone(e.target.value)}}  className="form-control field-text"  placeholder={ t('phone') }/>
                              <span style={{ color: "red"}}>{errPhone}</span>
                            </div>
                          </div>
                          <textarea onChange={e=>{handleMesage(e.target.value)}}   className="form-control field-textarea mt-3" cols={80} rows={6} placeholder={ t("your_msg") }></textarea><br/>
                          <span style={{ color: "red"}}>{ errMessage}</span> <br/>
                          <button type="submit" className="btn btn-primary">{ t('send_msg') }</button>
                          </form>
                </div>
              </div>
              <div className="col-md-4 font-sans">
                  <div className="address-content">
                      <div className="address address-info">
                          <h6>{ t("head_office")}</h6>
                          <hr/>
                          <p>
                            <i className="fas fa-map-marker-alt"></i> 
                            <span className="mx-1"></span> 
                            <span >{setting?.address}</span>
                          </p>
                          <p>
                            <i className="fas fa-phone-alt"></i> 
                            <span className="mx-1"></span> 
                            <span >{ setting?.phone} </span>
                          </p>
                          <p>
                            <i className="far fa-envelope"></i> 
                            <span className="mx-1"></span> 
                            <span>{ setting?.email}</span>
                          </p>
                      </div>
                      <div className="address">
                        <h6>{ t("connect_us") }</h6>
                        <hr/>
                        <ul className="social-icons contact-box-social">
                          <li><a className="facebook" href={setting?.facebook} target="_blank"><i className="fab fa-facebook-f"></i></a></li>
                          <li><a className="twitter" href={setting?.twitter} target="_blank"><i className="fab fa-twitter"></i></a></li>
                          <li><a className="linekdin"  target="_blank" href={setting?.linekdin}><i className="fab fa-linkedin-in"></i></a></li>
                          <li><a className="instagram" target="_blank" href={setting?.instagram}><i className="fab fa-instagram" ></i></a></li>
                        </ul>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </section>

      <section className="map-area  p-spacer">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title-row">
                <h4>{ t('find_location') }</h4>  
              </div>
            </div>
          </div>
          <div className="row">
              <div className="col-md-12">
                <small className="font-weight-bold text-muted pb-2">{ t("google_map") }</small>
                <div id="map" style={{height: "400px"}}>
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d58010.66069641818!2d46.6655639!3d24.6696123!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03e34eeffc47%3A0xfe09bb19801c399e!2sRiadah%20Incubators!5e0!3m2!1sen!2sbd!4v1630588941006!5m2!1sen!2sbd" width="100%" height="450" style={{ border:0 }}  title="gogole map" allowFullScreen={true} loading="lazy"></iframe>
              </div>
              </div>
          </div>
        </div>
      </section>
    </>
  );
}
