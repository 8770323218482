import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {  getSearchedProductByCategory } from '../api/products/ProductService';
import { Product } from '../components/products/Product';

export const SearchingProduct = () => {
    const location = useLocation();
    const {state} =  location as any;
     const {formData} = state;

     const [products, setProducts] = useState([]);

     useEffect(()=>{
            fetchSearchedProducts();
     },[]);

     const fetchSearchedProducts = async () => {
        const res = await getSearchedProductByCategory(formData);
        if(res?.success === true)
        {
             setProducts(res.data);
        }
     }

  


  return (
    <>
        <section className="best-seller-section p-spacer">
          <div className="container">
            <div className="row">
                {products.map((product:any)=>(
                    <Product product={product} key={product.id}/>
                ))}
            </div>
          </div>
        </section>
    </>
  );
};
