import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { userRegistrationService } from '../../../api/auth/AuthService';
import { ShowNotification } from '../../../components/Notification/ShowNotification';

export const SignUp = () => {
    const { t } = useTranslation();
    const [errFirstName, setErrFirstName ] = useState<string>("");
    const [errLastName, setErrLastName ] = useState<string>("");
    const [errEmail, setErrEmail] = useState<string>("");
    const [errPassword, setErrPassword] = useState<string>("");

    const handleSignUp = (event:any) => {
        event.preventDefault();
        const fd = new FormData(event.target);

         userRegistrationService(fd).then((res)=>{
              if(res?.success === true)
              {
                  ShowNotification(res.message, "success");
              }
              if(res?.status === 422)
              {
                  setErrFirstName(res.data.errors.first_name);
                  setErrLastName(res.data.errors.last_name);
                  setErrEmail(res.data.errors.email);
                  setErrPassword(res.data.errors.password);
              }
         });

    }
  return (
  <>
    <div className="container my-5">
        <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5 col-sm-7">
                <div className="p-5" style={{boxShadow: "0px 5px 15px 0px #00000040",borderRadius:"10px"}}>
                    <h5 className="text-center"><b>{ t('registration_form_title') }</b></h5>
                    <div className="pt-5">
                        <form onSubmit={handleSignUp}>
                            <div className="form-group ">
                                <input type="text" className="form-control" name="first_name"  autoComplete="first_name" autoFocus placeholder='First Name'/>
                                <span className="invalid-feedback" role="alert">
                                    <strong >{ errFirstName}</strong>
                                </span>   
                             
                            </div>

                            <div className="form-group ">
                                <input type="text" className="form-control " name="mobile"  autoComplete="mobile" autoFocus placeholder='Mobile'/>
                                <span className="invalid-feedback" role="alert">
                                    <strong >{errLastName}</strong>
                                </span>
                              
                            </div>

                            <div className="form-group">
                                <input  type="email" className="form-control" name="email"  autoComplete="email" placeholder='Email'/>
                                <span className="invalid-feedback" role="alert">
                                    <strong>{errEmail}</strong>
                                </span>
                            </div> 

                            <div className="form-group">
                                <input type="password" className="form-control" name="password"  placeholder="Password"/>
                                <span className="invalid-feedback" role="alert">
                                    <strong >{errPassword}</strong>
                                </span>
                            </div>

                            <div className="form-group row mb-0">
                                <button type="submit" className="btn btn-primary text-white w-100">
                                    { t('sign_up') }
                                </button> <br/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  </>)
};
