import axios from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";

export const storeContactService= async(data:any) =>{
    return  await axios.post(API_URL+V+"contact",data,{
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (error)=>{
                if (error.response) {
                   
                    return error.response;
                  
                  } else if (error.request) {
                    console.log(error.request);
                  } else {
                    console.log('Error', error.message);
                  }
                }
    
      )
}