import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteSellerProductService, getSellerProductsService } from '../../../api/products/ProductCrud';
import { ShowNotification } from '../../../components/Notification/ShowNotification';

export const ListProduct = () => {
    const [products, setProducts] = useState([])
    useEffect(()=>{
      fetchSellerProducts();
    },[]);

    const fetchSellerProducts =async () =>{
        const res = await getSellerProductsService();
        if(res?.success ===  true)
        {
          setProducts(res.data);
        }
    }

    const productDelete = (id:string) =>{
        deleteSellerProductService(id).then((res)=>{
           if(res!== "undefined" && res.success === true)
           {
               ShowNotification("Product deleted","success");
               fetchSellerProducts();
           }
           else{
              ShowNotification("Something error", "error");
           }
        })
    }

    
  return (
    <div className="container">
    <div className="row">
        <div className='col-12 d-flex justify-content-between'>
            <h2>Order List</h2>
            <Link to="/seller/product/create">Add Product</Link>
        </div>
        <div className="col-12 ">
            <table className="table table-responsive table-bordered">
                <thead>
                    <tr>
                            <th>SN</th>
                            <th>Name</th>
                            <th>Brand </th>
                            <th>Category</th>
                            <th>Price</th>
                            <th>Offer Price</th>
                            <th>Product Status </th>
                            <th>Action</th>
                    </tr>
                </thead>
                  <tbody>
                    {products.map((product:any,index:number)=>(
                         <tr>
                              <td>{index+1}</td>
                                    <td>{ product.name }</td>
                                    <td>{product?.brand?.name }</td>
                                    <td>{ product?.category?.name }</td>
                                    <td>{ product?.price }</td>
                                    <td>{ product?.offer_price }</td>
                                     <td>{(product.status)?(  "Pubished" ): ( "Non Published")}</td>
                                    <td>
                                        <Link to={`/seller/product/${product.id}`}>
                                                <i className="fa fa-edit"></i> 
                                        </Link>
                                        <button onClick={e=>productDelete(product.id)} style={{border:"none"}}><i className="fa fa-trash"></i></button>
                                    </td>
                         </tr>
                    ))}
                     
                  </tbody>
            </table>
        </div>
    </div>
    </div>
  )
};
