import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import translationEN from "../assets/locales/en/translations.json";
import translationAR from "../assets/locales/ar/translations.json";
import { URL } from "../components/base/BaseUrl";

const fallbackLng = ["en"];
const availableLanguages = ["en", "ar"];

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  },
};



i18n.use(Backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",

    interpolation: {
      escapeValue: false,
      formatSeparator: ','
    },
  });



export default i18n;

