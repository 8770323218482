import React from 'react'
import whoWe from "../images/common/Who-we-are.png"

export const WhoWeAre = () => {
  return (
    <section className='container my-5'>
    <div className='row'>
        <div className='col-sm-6 d-flex align-items-center'>
            <div>
            <h2>Who we are?</h2>
            <p>The vegetable and fruit incubator is a unique entity based on creativity and excellence with a global thought that consists of a system of local, regional and international partnerships concerned with supporting the vegetable and fruit sales sector and its related activities</p>
            <button className="btn btn-primary">About Us</button>
            </div>
        </div>
        <div className='col-sm-6'>
          <img src={whoWe} className="img-fluid" alt=''/>
        </div>
    </div>
</section>

  )
}
