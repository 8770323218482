import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export const CheckAuth = () => {
    const  location = useLocation();
    const navigate = useNavigate();

 
  
   
    useEffect(()=>{
        let user:any = localStorage.getItem("profile");
        if(user && user!="")
        {
            user = JSON.parse(user);
        }
        const token = user?.token
        if(token)
        {
            if (user.expires_at < new Date().getTime()) {
                localStorage.setItem("profile","");
            }
        }
        if(!user){
            navigate("/signin-user", {
                state: {
                    "prevPath" : location.pathname
                }
            });
        }
    },[location]);

   

  return (   
    <>
    </>
  );
};
