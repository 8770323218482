import React, { useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import { getEventSolutionCategoriesService } from '../../api/eventsolution/EventSolutionService';
import i18n from '../../config/i18n';
import { useTranslation } from 'react-i18next';
import { DesktopTopBar } from './DesktopTopBar';
import { MobilepTopBar } from './MobileTopBar';

export const Header = () => {
  const { t } = useTranslation();
  const [eventCategories, setEventCategories] = useState([]);



    useEffect(() =>{
       fetchEventSolutionCategoies();
    },[i18n.language])



    const  fetchEventSolutionCategoies = async () =>{
      const res = await getEventSolutionCategoriesService();
      if(res?.success===true)
      {
        setEventCategories(res.data);
      }
    }


    return(
      <>
        <header className="header-area">
        <DesktopTopBar/>
       <MobilepTopBar/>
       
			 <div className="menubar-area">
				<div className="container">
					<div className="row">
						<div className="col">
						  <nav className="navbar navbar-expand-lg navbar-light px-0 py-1 d-flex justify-content-between">
							<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
							  <span className="navbar-toggler-icon"></span>
							</button>
							<div className="collapse navbar-collapse menubar-right " id="navbarNav">
							  <ul className="navbar-nav ml-auto" >
                    <li className="nav-item ">
                        <Link className="nav-link" to="/">{t ("home")} </Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/services">Services</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/shop">Market</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/logistic">Logistic</Link>
                    </li>
                
                    <li className="nav-item">
                        <Link className="nav-link" to="/training">Training</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/warehouse">Warehouse</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link pr-0 apr-10" to="/laboratory">Laboratory & Sample</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link pr-0 apr-10" to="/numbering-coding">Numbering & Coding</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link pr-0 apr-10" to="/contact-us">Help</Link>
                    </li>
                    </ul>
							</div>
						  </nav>
						</div>
					  </div>
			</div>
        </div>

      
    </header>
  </>
    );
}
