import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
export const CheckSeller = () => {
    const  location = useLocation();
    const navigate = useNavigate();
   
    useEffect(()=>{
        let user:any = localStorage.getItem("profile");
        if(user && user!= "")
        {
            user = JSON.parse(user);
        }
        if(!user  && user.data.user_type!=2 ){
            navigate("/signin-user", {
                state: {
                    "prevPath" : location.pathname
                }
            });
        }
    },[location]);

   

  return (   
    <>
    </>
  );
};
