import axios from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";
import { token } from "../../utils/user";

export const storeProductService = async(data:any) =>{
    return  await axios.post(API_URL+V+"product",data,{
        headers:{
            'Content-Type': 'multipart/Form',
            'Accept': 'application/json',
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
        (error)=>{
            if (error.response) {
                 
                return error.response;
              
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
          }
      )
}


export const updateProductService = async(data:any, id:any) =>{
    return  await axios.put(API_URL+V+"product/"+id,data,{
        headers:{
            'Content-Type': 'multipart/Form',
            'Accept': 'application/json',
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}


export const getSellerProductsService = async() =>{
    return  await axios.get(API_URL+V+"product",{
        headers:{
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}

export const showSellerProductsService = async(id:any) =>{
    return  await axios.get(API_URL+V+"product/"+id,{
        headers:{
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}



export const deleteSellerProductService = async(id:any) =>{
    return  await axios.delete(API_URL+V+"product/"+id,{
        headers:{
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
        (error)=>{
            if (error.response) {
                 
                return error.response;
              
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
          }
      )
}