import React,{useEffect, useState} from  'react';
import { useTranslation } from 'react-i18next';
import { getUserDetailsService, updateProfileService } from '../../api/auth/AuthService';
import { ShowNotification } from '../../components/Notification/ShowNotification';
export const ProfileUpdate = () => {
  const { t } = useTranslation();
  const [userDetails, setUserDetails] = useState<any>({});
  const [ errFirstName, setErrFirstName ] = useState<string>("")
    const [ errLastName, setErrLastName ] = useState<string>("")
    const [ errEmail, setErrEmail ] = useState<string>("");
    const user = JSON.parse(localStorage.getItem("profile")||"")

    useEffect(()=>{
         fetchUserDetails();
    },[])


    const fetchUserDetails = async () =>{
        const res = await getUserDetailsService();
        if(res?.success=== true)
        {
          setUserDetails(res.data);
        }
    }

    const hanldeProfileUpdate = (event:any)=>{
        event.preventDefault();
        const data = new FormData(event.target);
        console.log(data);
        updateProfileService(data).then((res)=>{
          if(res.success === true)
          {
            ShowNotification("Profile updated success","success");
          }
        if(res.status === 422)
        {
              setErrFirstName(res.data.errors.first_name);
              setErrLastName(res.data.errors.last_name); 
              setErrEmail(res.data.errors.email);   
        }
        })
    }

  return (
     <>
           <div className="container" >
            <div className="row">
            <form className='p-5 bg-white profile-update' encType='multipart/form-data' onSubmit={hanldeProfileUpdate} style={{boxShadow:"5px 5px 5px #ffffff"}}>
                 <h2 className='text-center pb-3'>Profile Update</h2>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label >First Name</label>
                      <input type="text" name="first_name" className="form-control" value={user.data.first_name}/>
                      <div className='invalid-feedback'>
                          <p>{errFirstName}</p>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Last Name</label>
                      <input type="text" name="last_name" className="form-control" value={user.data.last_name}/>
                      <div className='invalid-feedback'>
                          <p>{errLastName}</p>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input type="email" className="form-control" name="email" value ={user.data.email}/>
                      <div className='invalid-feedback'>
                          <p>{errEmail}</p>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Phone</label>
                      <input type="text" className="form-control" value={userDetails.phone} name="phone"/>
                    </div>

                    <div className="form-group col-md-6">
                      <label >City</label>
                      <input type="text" className="form-control" value={userDetails.city} name="city"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Country</label>
                      <input type="text" className="form-control" value={userDetails.country}  name="country"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Postal Code</label>
                      <input type="text" className="form-control"value={userDetails.postal_code}  name="postal_code"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Photo</label>
                      <input type="file" className="form-control"  name="photo"/>
                    </div>
                   
                  </div>
                  <div className="form-group">
                    <label >Address</label>
                    <input type="text" className="form-control" value={userDetails.address} name="address" placeholder="1234 Main St"/>
                  </div>
     
                  <button type="submit" className="btn btn-primary">Update </button>
                </form>
           </div>
          </div>
      </>
  )
};
