import axios from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";
import i18n from "../../config/i18n";

export const getlatestFeaturedProductsService= async() =>{
    return  await axios.get(API_URL+V+"latest-featured-products",{
        headers:{
            "X-localization": i18n.language
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
    }


    export const getlatestTrendingProductsService= async() =>{
        return  await axios.get(API_URL+V+"latest-trending-products",{
            headers:{
                "X-localization":i18n.language
            }
        }).then(
              (res)=>{
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }

    export const getTrendingProductsService= async() =>{
        return  await axios.get(API_URL+V+"trending-products",{
            headers:{
                "X-localization": i18n.language
            }
        }).then(
              (res)=>{
                 
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }


    export const getExibitionEquipmentGalleryService= async() =>{
        return  await axios.get(API_URL+V+"exhibition-equipment-gallery",{
            headers:{
                "X-localization": i18n.language
            }
        }).then(
              (res)=>{
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }


    export const getBestOfferProductsService= async() =>{
        return  await axios.get(API_URL+V+"best-offer-products",{
            headers:{
                "X-localization":i18n.language
            }
        }).then(
              (res)=>{
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }


    export const getNewArrivalProductsService= async() =>{
        return  await axios.get(API_URL+V+"new-arrival-products",{
            headers:{
                "X-localization": i18n.language
            }
        }).then(
              (res)=>{
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }

    export const getSearchedProductByCategory= async(data:any) =>{
        return  await axios.post(API_URL+V+"category/search/product",{
            headers:{
                "X-localization": i18n.language
            }
        }, data).then(
              (res)=>{
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }

    export const getProductByCategoryService= async(id:string|undefined) =>{
        return  await axios.get(API_URL+V+"category/"+id+"/products",{
            headers:{
                "X-localization": i18n.language
            }
        }).then(
              (res)=>{
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }


    export const getProductDetailsService= async(id:string|undefined) =>{
        return  await axios.get(API_URL+V+id+"/product-details",{
            headers:{
                "X-localization": i18n.language
            }
        }).then(
              (res)=>{
                  return res.data;
              }
          ).catch(
              (err)=>{
                  console.log(err.toJSON());
              }
          )
    }

    