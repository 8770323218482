import React, { useEffect, useState } from 'react';
import { URL } from '../components/base/BaseUrl';
import { getAboutUsService } from '../api/about-us/AboutUsService';

import aboutService from "../images/about_service.png"
import { getAboutUsServicesList } from '../api/about-us/AboutUsServicesList';
import parse from 'html-react-parser';
import i18n from '../config/i18n';
import { useTranslation } from 'react-i18next';

export const About = () => {
  const { t } = useTranslation();
    const [about,setAbout] = useState<any>({});
    const [services, setServices] = useState<any>([])
    useEffect(()=>{
        fetchAboutUs();
        fetchAboutUsServices();
    },[i18n.language])

    async function fetchAboutUs()
    {
        const res = await getAboutUsService();
        if(res.success === true)
        {
            setAbout(res.data);
        }
    }

    const fetchAboutUsServices = async () =>{
        const res = await getAboutUsServicesList();
        if(res.success === true)
        {
            setServices(res.data);
        }
    }
  return (
    <>
         <section className="title-with-bg-area">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                      <div className="title-with-bg">
                        <h4>{ t("about_us") } </h4>
                      </div>
                    </div>
                  </div>
            </div>
        </section>


      <section className="about-eventincu-area pt-5 p-spacer">
        <div className="container">
          <h5>{t("event_incubator") }</h5>
          <hr/>
          <div className="row pt-4">
            <div className="col-md-6">
              <img  src={URL+about.photo} alt="" className="img-fluid w-100"/>
            </div>
            <div className="col-md-6">
              <div className="know-about-content">
                <hr/>
                <p  className="text-justify">{about.about_us}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="vision-area">
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                    <div className="vision-message-content">
                        <div className="vision-content">
                            <h6>{ t("our_vision") }</h6>
                            <hr/>
                            <p >{about.vision}</p>
                        </div>
                        <div className="message-content">
                            <h6>{t("our_message") }</h6>
                            <hr/>
                            <p>{about.message}</p>
                        </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                      <img src={URL+about.vision_photo} alt="" className="img-fluid p-5"/>
                  </div>
              </div>
          </div>
      </section>



      <section className="about-end-area pt-5 p-spacer">
        <div className="container">
          <h5>{ t("event_incubator") }</h5>
          <hr/>
          <div className="row pt-4">
            <div className="col-md-6">
              <img src={aboutService} alt="" className="img-fluid w-100"/>
            </div>
            <div className="col-md-6">
              <div className="about-end-content">
                <ul>
                    {services.map((service:any)=>(
                    <li key={service.id}>
                       <div>
                       {parse('<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><style>.a{stroke-linejoin:round;stroke-width:2;stroke:#FF7A00;}</style><path d="M17 8.3V9C17 10.7 16.4 12.4 15.4 13.8 14.4 15.2 12.9 16.2 11.3 16.7 9.6 17.2 7.8 17.1 6.2 16.5 4.6 15.9 3.2 14.8 2.3 13.4 1.3 11.9 0.9 10.2 1 8.5 1.1 6.8 1.8 5.1 2.9 3.8 4 2.5 5.6 1.6 7.2 1.2 8.9 0.8 10.7 1 12.3 1.7" className="a"/><path d="M17 2.6L9 10.6 6.6 8.2" className="a"/></svg>')}
                        </div>
                        <div className="mx-2">{service.service}
                        </div>
                    </li>
                    ))}
                </ul>  
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
