import React, { Suspense, useEffect, useState } from 'react';

import {BrowserRouter,Routes,Route} from "react-router-dom"
import { getCurrenciesService } from './api/settings/Setting';
import { CurrenciesContext } from './components/context/CurrencyContext';
import i18n from './config/i18n';

import { NotificationContainer } from './components/Notification/NotificationContainer';

import { PublicRoute } from './Routes/PublicRoute';
import { ClientPanelRoute } from './Routes/ClientPanelRoute';
import { SellerRoute } from './Routes/SellerRoute';
import { CartProvider } from 'react-use-cart';
import "./assets/css/bootstrap.min.css";
import "./assets/css/ClientPanel.css";
import "./assets/css/style.css"
import { Spinner } from './components/Spinner';

function App() {
 
    const [currencies, setCurrencies] = useState<any>([]);
    const [currencyName, setCurrencyName] = useState<string>("")
    const [symbol, setSymbol ]  = useState<string>("");
    const [exchangeRate, setExchangeRate ]  = useState<number>(0);
  
    document.body.dir = i18n.dir();
    let arabic = document.getElementById("arabicCss");

    if(i18n.dir()==="rtl"){
      if(arabic){
         arabic.setAttribute("href","css/arabic.css?v="+new Date().getTime());
      }
    }
    else{
      if(arabic)
      arabic.setAttribute("href","");
    }

   
    const currencyData = {
        currencyName :currencyName,
        setCurrency :  (name:string)=>{
  
            let currency:any;
              currencies.forEach(function(current:any){
  
                  if(current.name === name)
                  {
                    currency = current;
                  }
              })

            setCurrencyName(currency?.name);
            setExchangeRate(currency?.exchange_rate);
            setSymbol(currency?.symbol)
        },
  
        currencies: currencies,
        symbol : symbol,
        exchangeRate : exchangeRate,
    };

    useEffect(()=>{
      fetchCurrencies();
    },[i18n.language]);


  const  fetchCurrencies = async () =>{
    const res = await getCurrenciesService();
    if(res.success===true)
    {
       setCurrencies(res.data);
       setCurrencyName(res.data[0].name);
       setExchangeRate(res.data[0].exchange_rate);
       setSymbol(res.data[0].symbol);
    }
  }




  return (
  
       <>
   
     <Suspense fallback={<Spinner/>}>
       <CartProvider>
         <BrowserRouter>
         <CurrenciesContext.Provider value={currencyData}>
            <NotificationContainer/>
              <Routes>
                    <Route path="/client-panel/*" element={<ClientPanelRoute/>}/>
                   <Route path="/*" element ={<PublicRoute/>}/>
                   <Route path="/seller/*" element ={<SellerRoute/>}/> 
              </Routes> 
            </CurrenciesContext.Provider>
         </BrowserRouter>
         </CartProvider>
         </Suspense>
       </>
  );
}

export default App;
