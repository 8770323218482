import React, { useEffect, useState} from 'react';

import { getCategoriesService } from '../../api/category/CategoeryService';
import { CurrenciesContext } from '../context/CurrencyContext';
import { getEventSolutionCategoriesService } from '../../api/eventsolution/EventSolutionService';
import { Link, useNavigate } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';


function  Layout(props:any){
 return(
  <>
    <Header/>
    {props.children}
    <Footer/>
  </>
    )
 
}

export default Layout
