import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { createOrderService } from '../api/order/Order';
import { CheckAuth } from '../components/CheckAuth';
import { CurrenciesContext } from '../components/context/CurrencyContext';
import { ShowNotification } from '../components/Notification/ShowNotification';

export const Checkout = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { items, emptyCart } = useCart();
    const location = useLocation();
    const {state} = location as any
    const handleOrder =async (e:any) =>{
        e.preventDefault();
        const data = new FormData(e.target);
         createOrderService(data).then((res)=>{
            console.log(res);
            let orderId = 0;
            if(res?.success=== true)
            {
                emptyCart();
                orderId= res.data;     
                navigate("/create-order",{state:{
                    orderId,
                }})
            }
            else{
                ShowNotification("Please provide validate data","error");
            }
         });
       
       
    }

  return (
    <>
     <CheckAuth/>
       <form  onSubmit={handleOrder}>
    <section className="checkout-area">
        <div className="container">
            <div className="row text-center">
                <div className="col-12 checkout-title">
                    <h5>{t('fill_up') }</h5>
                </div>
            </div>
        </div>
         <div className="container">
            <div className="row">
                 {/*-- billing information field -->*/}
                <div className="col-md-6 col-12"> 
                    <div className="billing">
                        <p className="border-bottom-c">{ t('bill_inf') }</p>
                            <span className="hidden-field"></span>
                            <div className="row">
                              <div className="col">
                                <label id="first_name">{ t('first_name') }</label>
                                <input type="text" name="first_name" />
                              </div>
                              <div className="col">
                                 <label >{  t('last_name')} </label>
                                <input type="text" name="last_name"/>
                              </div>
                            </div>
                            <div className="form-group">
                                <label >{ t('company_name') }</label>
                                <input type="text"  name="company_name" />
                              </div>
                            <div className="form-group">
                                <label>{ t('country_name')} </label>
                                <select name="country_id" className="form-control">
                                     <option value="1">Saudi Arabia</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="street_address">{ t('street_address') }</label>
                                 <textarea name="street_address" className="form-control" style={{border:"1px solid #ced4da"}}></textarea>
                            </div>
                           
                            <div className="form-group">
                                <label >{ t('city') }</label>
                                 <select name="city_id" className="form-control">
                                        <option value="1">Riyadh</option>
                                        <option value="2">Zeddah</option>
                                 </select>
                            </div>
                            <div className="form-group">
                                <label>{ t('zip_code') }</label>
                                <input type="text" name="zip_code"/>
                            </div>
                            <div className="form-group">
                                <label >{ t('con_num') }</label>
                                <input type="text" name="phone" />
                            </div>
                            <div className="form-group">
                                <label >{ t('email_addr')} </label>
                                <input type="text" name="mail" id="email"/>
                            </div>
                            <div className="form-group">
                                <label >{ t('order_note') }</label>
                                <textarea name="order_note" rows={3}>
                                    
                                </textarea>
                            </div>
                        
                    </div>
                </div>

                {/*-- end billing information -

                order detials --> */}
                <div className="col-md-6 col-12">
                    <p  className="border-bottom-c">{ t('your_order') }</p>
                    <div className="order-details">
                        <div className="row border-bottom-c">
                            <div className="col">
                                <p>{ t('prod_name') }</p>
                            </div>
                            <div className="col text-right">
                                <p> { t('sub_total')} </p>
                            </div>
                        </div>
                        <span className="product-details">
                                <input type="hidden" name="coupon_code" value={state.coupon_code}/>
                                <CurrenciesContext.Consumer>
                                    {({currencyName}) => (
                                          <input type="hidden" name="currency" value={currencyName}/>
                                    )}
                                </CurrenciesContext.Consumer>
                               
                                {(items.map((item:any,index:number)=>(   
                                    <div className="row">
                                          <div className="col-8">
                                                <p>{item.name}</p>
                                            </div>
                                            <div className="col-4 text-right">
                                            
                                            <CurrenciesContext.Consumer>
                                                {({symbol,exchangeRate}) => (
                                                    <>
                                                    <h6>{symbol}{item.price*item.quantity*exchangeRate}</h6> 
                                                    <input type="hidden" value={item.id} name="product_id[]"/>
                                                    <input type="hidden" value={item.quantity} name="qty[]"/>
                                                    </>
                                                )}
                                                </CurrenciesContext.Consumer>
                                        </div>
                                        </div>
                                )))}
                            
                        </span>
                      
                       
                        <div className="row">
                            <div className="col-8">              
                                <p> { t('shipping_charge') }</p>
                            </div>
                            <div className="col-4 text-right">
                                <p className="shipping-charge">0</p>
                            </div>
                        </div>
                        <div className="row border-top-c">
                            <div className="col-8">              
                                <p>{ t('total') }</p>
                            </div>
                            <div className="col-4 text-right">
                                <p className="total-price">   
                                <CurrenciesContext.Consumer>
                                    {({symbol,exchangeRate}) => (
                                        <>
                                        <span >{symbol}{state?.total*exchangeRate}</span> 
                                        
                                        </>
                                    )}
                                    </CurrenciesContext.Consumer></p>
                            </div>
                        </div>
                        <div className="row border-top-c">
                            <div className="col-8">              
                                <p>{ t('discount') }</p>
                            </div>
                            <div className="col-4 text-right">
                                <p className="discount">   
                                <CurrenciesContext.Consumer>
                                    {({symbol,exchangeRate}) => (
                                        <>
                                        <span >{symbol}{state.discount*exchangeRate}</span> 
                                        
                                        </>
                                    )}
                                    </CurrenciesContext.Consumer></p>
                            </div>
                        </div>
                        <div className="row border-top-c">
                            <div className="col-8">              
                                <p>{ t('total_pay') } </p>
                            </div>
                            <div className="col-4 text-right">
                                <p className="total-payable">   
                                <CurrenciesContext.Consumer>
                                    {({symbol,exchangeRate}) => (
                                        <>
                                        <span >{symbol}{ (state.total-state.discount)*exchangeRate}</span> 
                                        
                                        </>
                                    )}
                                    </CurrenciesContext.Consumer></p>
                            </div>
                        </div>
                       
                            <div className="form-group">
                                <div className="form-check">
                                  <input className="form-check-input" value="bank transfer" type="radio" name="payment"/>
                                  <label className="form-check-label" >
                                     {t('bank_trans')}
                                  </label>
                                </div>
                                <p>{ t('make_your_payment') }</p>
                              </div>
                              <div >
                                 
                              </div>
                              <div className="credit_card">
                                  <div className="row">
                                      <div className="col">
                                        <div className="form-group">
                                            <div className="form-check">
                                              <input className="form-check-input" type="radio" value="Credit Card" name="payment"/>
                                              <label className="form-check-label">
                                                { t('credit_card')}
                                              </label>
                                            </div>
                                          </div>
                                      </div>
                                      <div className="col">

                                      </div>
                                  </div>
                                  <p>{ t('make_your_payment') }</p>
                              </div>
                             
                              <div className="form-group">
                                <div className="form-check">
                                  <input className="form-check-input" type="checkbox" name="term_condition"/>
                                  <label className="form-check-label" >
                                    <a href="#">{ t('i_agree') } </a>
                                  </label>
                                </div>
                              </div>
                          </div>
                          <div className="form-group mt-5">
                             <button type="submit" className="custom-btn form-control btn-primary text-white ">{ t('place_order') }</button>
                          </div>
                    </div>
                </div>
            </div>
   
    </section>
</form>
    </>
  );
};
