import React, { useEffect, useState } from 'react';
import { URL } from '../components/base/BaseUrl';
import { Product } from '../components/products/Product';
import { SideContentProduct } from '../components/products/SideContentProduct';
import { Subscribe } from '../components/Subscribe';
import { Category } from '../models/Category';
import { getCategoriesService } from '../api/category/CategoeryService';
import { getBestOfferProductsService, getExibitionEquipmentGalleryService, getlatestFeaturedProductsService, getNewArrivalProductsService, getTrendingProductsService } from '../api/products/ProductService';
import { ShowNotification } from '../components/Notification/ShowNotification';
import i18n from '../config/i18n';
import { useCart } from 'react-use-cart';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CurrenciesContext } from '../components/context/CurrencyContext';
import { SlideProduct } from '../components/products/SlideProduct';
import Carousel from 'react-multi-carousel';
import product1  from "../images/Homepage/shop/image--01.png";
import product2  from "../images/Homepage/shop/image--02.png";
import product3  from "../images/Homepage/shop/image--03.png";
import product4  from "../images/Homepage/shop/image--04.png";
import product5  from "../images/Homepage/shop/image--05.png";
import product6  from "../images/Homepage/shop/image--06.png";
import product7  from "../images/Homepage/shop/image--07.png";

export function ShopNow(){
    const { t } = useTranslation();
    const [newProds, setNewProds] = useState([]);
    const [bestProds, setBestProds] = useState([]);
    const [feaProds, setFeaProds ]  = useState([]);
    const [trenProds, setTrenProds] = useState([]);
    const [categories, setCategories] = useState<Category[]>([])
  const [exProds, setExProds] = useState([])
    const { addItem } = useCart();

    const responsive = {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 6,
        slidesToSlide: 1 // optional, default to 1.
      },
      tablet: {
        breakpoint: { max: 1024, min: 600 },
        items: 4,
        slidesToSlide: 2 // optional, default to 1.
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
        slidesToSlide: 1 // optional, default to 1.
      }
    };

    const products = [{
        "id":"1",
        "name":"Fold Carton packaging box",
        "photo":product1,
        "price":"12"
    },
    {
        "id":"2",
        "name":"Fold Carton packaging box",
        "photo":product2,
        "price":"12"
    },
    {
        "id":"3",
        "name":"Fold Carton packaging box",
        "photo":product3,
        "price":"12"
    },
    {
        "id":"4",
        "name":"Fold Carton packaging box",
        "photo":product4,
        "price":"12"
    },
    {
        "id":"5",
        "name":"Fold Carton packaging box",
        "photo":product5,
        "price":"17"
    },
    {
        "id":"6",
        "name":"Fold Carton packaging box",
        "photo":product6,
        "price":"12"
    },
    {
        "id":"7",
        "name":"Fold Carton packaging box",
        "photo":product7,
        "price":"12"
    },
]
    useEffect(()=>{
        fetchCategories();
        fetchExibitionGalleryProduct();
        fetchBestOfferProducts();
        fetchNewArrivalProds();
        fetchLatestFeaturedProducts();
        fetchTrendingProducts();
    },[i18n.language])

   
    const fetchCategories = async () =>{
      const res = await getCategoriesService();
      if(res?.success === true)
      {
          setCategories(res.data);
      }
  }

  const fetchExibitionGalleryProduct = async () =>{
    const res = await getExibitionEquipmentGalleryService();
    if(res?.success === true)
    {
        setExProds(res.data);
    }
  }


   
    const fetchBestOfferProducts = async () => {
        const res = await getBestOfferProductsService();
        if(res?.success === true)
        {
            setBestProds(res.data);
        }
    }

    const fetchNewArrivalProds = async () => {
        const res = await getNewArrivalProductsService();
        if(res?.success === true)
        {
            setNewProds(res.data);
        }
    }

    const fetchLatestFeaturedProducts = async () => {
        const res = await getlatestFeaturedProductsService();
        if(res?.success===true)
        {
          setFeaProds(res.data);
        }
     }

    const fetchTrendingProducts = async () =>{
        const res = await getTrendingProductsService();
        if(res?.success === true)
        {
            setTrenProds(res.data);
        }
    }

    const addProductToCart = (product:any) =>{
         if(product.offer_price!=null && product.offer_price>=1){
            product.price= product.offer_price;
         } 
        addItem(product);
        ShowNotification("Product added in Cart","success");
    }
  return (
  <>
       {/* categories and exibition product  gallery */}
       <section className="category-area p-spacer">
         <div className="container">
           <div className="row">
             <div className="col-lg-4">
              <div className="main-categories">
                <h6>{ t("categories") }</h6>
                <hr/>
                <ul>
                   
                    <li> 
                        <Link  to=""> <i className="fas fa-cubes"></i>
                            <span className="mx-1"></span>
                            <span>Fresh Vegetables</span>
                        </Link> 
                    </li>
                    <li> 
                        <Link  to=""> <i className="fas fa-cubes"></i>
                            <span className="mx-1"></span>
                            <span> Organic Vegetables</span>
                        </Link> 
                    </li>
                    <li> 
                        <Link  to=""> <i className="fas fa-cubes"></i>
                            <span className="mx-1"></span>
                            <span> Fresh Fruits</span>
                        </Link> 
                    </li>
                    <li> 
                        <Link  to=""> <i className="fas fa-cubes"></i>
                            <span className="mx-1"></span>
                            <span>Organic Fruits</span>
                        </Link> 
                    </li>
                    <li> 
                        <Link  to=""> <i className="fas fa-cubes"></i>
                            <span className="mx-1"></span>
                            <span>Fresh Leaves & Herbs</span>
                        </Link> 
                    </li>
                    <li> 
                        <Link  to=""> <i className="fas fa-cubes"></i>
                            <span className="mx-1"></span>
                            <span>Frozen Papers</span>
                        </Link> 
                    </li>
                  
                </ul>
              </div>
             </div>
             <div className="col-lg-8 ">
                <div className="category-products-section">
                    <div className="row">  
                        <div className="col-md-4 col-sm-6" key={1}>
                            <div className="category-product">
                            <img src={product1} alt="" className="img-fluid"/>
                          
                            <a href="" className="cp-buy-now shopping-cart-product text-white px-2">
                             <span></span>
                          
                                <span >$12</span><br/>
                          
                                <span >Bangladeshi Frash Carrot </span><br/>
                                <span>1000 gm</span>
                               
                          </a>
                          <button onClick={(e)=>{e.preventDefault(); }} className="cp-buy-now shopping-cart-add-cart text-white w-100 border-0">
                                <i className="uil uil-shopping-cart"></i>
                                <span className="mx-1"></span>
                                <span>{ t("add_to_cart")}</span>
                            </button>
                          
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6" key={1}>
                            <div className="category-product">
                            <img src={product2} alt="" className="img-fluid"/>
                          
                            <a href="" className="cp-buy-now shopping-cart-product text-white px-2">
                             <span></span>
                          
                                <span >$12</span><br/>
                          
                                <span >Bangladeshi Frash Carrot </span><br/>
                                <span>1000 gm</span>
                               
                          </a>
                          <button onClick={(e)=>{e.preventDefault(); }} className="cp-buy-now shopping-cart-add-cart text-white w-100 border-0">
                                <i className="uil uil-shopping-cart"></i>
                                <span className="mx-1"></span>
                                <span>{ t("add_to_cart")}</span>
                            </button>
                          
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-6" key={1}>
                            <div className="category-product">
                            <img src={product3} alt="" className="img-fluid"/>
                          
                            <a href="" className="cp-buy-now shopping-cart-product text-white px-2">
                             <span></span>
                          
                                <span >$12</span><br/>
                          
                                <span >Bangladeshi Frash Carrot </span><br/>
                                <span>1000 gm</span>
                               
                          </a>
                          <button onClick={(e)=>{e.preventDefault(); }} className="cp-buy-now shopping-cart-add-cart text-white w-100 border-0">
                                <i className="uil uil-shopping-cart"></i>
                                <span className="mx-1"></span>
                                <span>{ t("add_to_cart")}</span>
                            </button>
                          
                            </div>
                        </div>
                   
                    </div>
                </div>
             </div>
           </div>
         </div>
        </section>
  
    
      

         {/* best offers product*/}

         <section className="category-area p-spacer" dir='ltr'>
                <div>
                    <h5 className='text-center'>{ t("best_offers") }</h5>
                    <hr/>
                </div>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                    {products.map((product:any)=>(
                    <SlideProduct product={product} addProductToCart={addProductToCart} key={product.id}/>
                ))}
                   </Carousel>
                
        </section>

        
         {/*  new arrivals  products*/}

         <section className="category-area p-spacer" dir='ltr'>
                <div>
                    <h5 className='text-center'>{ t("best_offers") }</h5>
                    <hr/>
                </div>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                    {products.map((product:any)=>(
                    <SlideProduct product={product} addProductToCart={addProductToCart} key={product.id}/>
                ))}
                   </Carousel>
                
        </section>
        <section className="category-area p-spacer" dir='ltr'>
                <div>
                    <h5 className='text-center'>{ t("best_offers") }</h5>
                    <hr/>
                </div>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                    {products.map((product:any)=>(
                    <SlideProduct product={product} addProductToCart={addProductToCart} key={product.id}/>
                ))}
                   </Carousel>
                
        </section>


        {/** subscribe for notification */}
        <Subscribe/>
        
  </>
  );
};
