import React from 'react';
import Notifications from 'react-notify-toast'


export const NotificationContainer = () => {
  return (
    <>
         <Notifications options={{zIndex: 200, top: '0px',right:"10px"}} />
    </>
  );
};
