import React from 'react'
import { Subscribe } from '../components/Subscribe'
import what1 from "../images/training/vegetablefruit/Spreading-awareness-to-improve-production-quality.png"
import what2 from "../images/training/vegetablefruit/Training-farmers-to-produce-the-finest-and-best-crops.png"
import what3 from "../images/training/vegetablefruit/Training-of-workers-on-packaging-work.png"
import what4 from "../images/training/vegetablefruit/Training-workers-on-proper-transportation-and-storage-work.png"

export const Training = () => {
  return (
      <>
     <section  className='training-title-bg'>

     </section>

     <section className='my-5'>
          <div className='container'>
              <div className='row'>
                   <div className='col-12'>
                       <div className='row'>
                       <div className='col-sm-7'>
                      <h2><b>At the vegetable and fruit stand we do</b></h2>
                  </div>
                       </div>
                   </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className ="card" >
                            <img className="card-img-top" src={what1} alt="Card  cap"/>
                            <div className="card-body">
                              
                                <p className="card-text">Training farmers to produce the finest and best crops</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className ="card" >
                            <img className="card-img-top" src={what2} alt="Card  cap"/>
                            <div className="card-body">
                               
                                <p className="card-text">Training of workers on packaging work</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className ="card" >
                            <img className="card-img-top" src={what3} alt="Card  cap"/>
                            <div className="card-body">
                                
                                <p className="card-text">Spreading awareness to improve production quality</p>
                               
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-4 col-sm-6'>
                        <div className ="card" >
                            <img className="card-img-top" src={what4} alt="Card  cap"/>
                            <div className="card-body">
                          
                                <p className="card-text">Training workers on proper transportation and storage work</p>
                               
                            </div>
                        </div>
                    </div>
              </div>
          </div>
     </section>
     <Subscribe/>
     </>
  )
}
