import React, { useEffect, useState } from 'react';
import { ServiceCard } from '../components/service/ServiceCard';
import { getServices } from '../api/OurService';
import i18n from '../config/i18n';
import { useTranslation } from 'react-i18next';

import WhyChoose from "../images/services/why-choose-us.png"
import { Subscribe } from '../components/Subscribe';
import { WhoWeAre } from '../components/WhoWeAre';


export const OurServices = () => {

  const {t} = useTranslation()
  useEffect(()=>{
    fetchServices();
  },[i18n.language]);

  const fetchServices = async () =>{
      const res = await getServices();
      console.log(res);
      if(res?.success===true)
      {
         //setServices(res.data);
      }
  }
  return(
    <>
       <section className="service-title-area p-spacer">
          <div className="container">
            <div className="service-title">
              <h2>{ t('our_services') }</h2>
              <p>{ t("take_services") } </p>
            </div>
          </div>
        </section>

        <WhoWeAre/>

        <section className='why-choose'>
            <div className="container mt-5">
              <div className='row'>
                  <div className='col-12  text-center my-5'>
                      <h2><b>Why Choose Us</b></h2>
                      <hr/>
                  </div>
                  <div className='col-sm-6'>
                      <img src={WhyChoose}  className="img-fluid" alt=''/>
                  </div>
                  <div className='col-sm-6'>
                      <ul>
                        <li><svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 1L4 11.01L1 8.01" stroke="#8BC34A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
Food security, waste reduction and efficient resource management</li>
<li>
<svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 1L4 11.01L1 8.01" stroke="#8BC34A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>An integrated commercial market
   <ul>
       <li>A means of linking farmers and traders</li>
       <li>Provides display of all types of vegetable and fruit crops</li>
       <li>Ease of choosing the right merchant and the best price</li>
       <li>The ability to browse and choose the best types of crops</li>
   </ul>
</li>
<li><svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14 1L4 11.01L1 8.01" stroke="#8BC34A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>Save time and money
<ul>
     <li>The market offers hundreds of crops for easy selection of the best price</li>
     <li>A platform for many traders to sell the crop at the right price</li>
     <li>Saving the trouble of searching for a dealer and a suitable price</li>
</ul>
</li>
                      </ul>
                  </div>
              </div>
            </div>
        </section>

        <section className='service-area my-5'>
          <div className='container'>
               <div className='row'>
                   <div className='col-12 text-center mt-5'>
                       <h2><b>Services</b></h2>
                        <hr/>
                   </div>
                    <div className="service-box">
                          <div className='service'>
                              <h6>01 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>02 / Services</h6>
                               <p>Provides display of all types of vegetable and fruit crops</p>
                          </div>
                          <div className='service'>
                              <h6>03 / Services</h6>
                               <p>Ease of choosing the right merchant and the best price</p>
                          </div>
                          <div className='service'>
                              <h6>04 / Services</h6>
                               <p>AThe ability to browse and choose the best types of crops</p>
                          </div>
                          <div className='service'>
                              <h6>05 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>06 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>07 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>07 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>07 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>07 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>07 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                          <div className='service'>
                              <h6>07 / Services</h6>
                               <p>A means of linking farmers and traders</p>
                          </div>
                    </div>
               </div>
          </div>
        </section>

        <Subscribe/>
  
       
    </>
  );
};
