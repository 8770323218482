import axios from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";
import i18n from "../../config/i18n";
import { token } from "../../utils/user";

export const userRegistrationService = async(data:any) =>{
    return  await axios.post(API_URL+V+"user/signup",data,{
        headers:{
            "X-localization": i18n.language,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (error)=>{
            if (error.response) {
                   
                return error.response;
              
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        }
      )
}

export const sellerRegistrationService = async(data:any) =>{
  return  await axios.post(API_URL+V+"seller/signup",data,{
      headers:{
          "X-localization": i18n.language,
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type',
      }
  }).then(
        (res)=>{
            return res.data;
        }
    ).catch(
        (error)=>{
          if (error.response) {
                 
              return error.response;
            
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
      }
    )
}


export const userLoginService = async(data:any) =>{
    return  await axios.post(API_URL+V+"user/login",data,{
        headers:{
            "X-localization": i18n.language,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
            'Access-Control-Allow-Headers': 'Content-Type',
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (error)=>{
            if (error.response) {
                   
                return error.response;
              
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
        }
      )
}

export const updateProfileService = async(data:any) =>{
  return  await axios.post(API_URL+V+"user/details",data,{
      headers:{
          'Content-Type': 'multipart/form-data',
          'Accept': 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type',
          "Authorization":"Bearer "+token()
      }
  }).then(
        (res)=>{
            return res.data;
        }
    ).catch(
        (error)=>{
          if (error.response) {
                 
              return error.response;
            
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
      }
    )
}

export const getUserDetailsService= async() =>{
  return  await axios.get(API_URL+V+"user/details",{
    headers:{
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      "Authorization":"Bearer "+token()
  }
  }).then(
        (res)=>{
            return res.data;
        }
    ).catch(
        (err)=>{
            console.log(err.toJSON());
        }
    )
}