import React from 'react'
import { Subscribe } from '../components/Subscribe'
import laboratory1 from "../images/laboratory/safety of the consumer/Ensure-the-quality-of-organic-crops.png"
import laboratory2 from "../images/laboratory/safety of the consumer/Ensure-the-safety-of-frozen-crops.png"
import laboratory3 from "../images/laboratory/safety of the consumer/Inspect-crops-and-ensure-their-safety.png"
import laboratory4 from "../images/laboratory/safety of the consumer/Inspect-crops-to-find-out-the-quality-of-fertilization-used.png"


export const Laboratory = () => {
  return (
      <>
        <div className='laboratory-bg' >
            
        </div>

        <section className='my-5'>
           <div className='container'>
               <div className='row'>
                    <div className='col-12'>
                    <h2><b>In cooperation with the Aria4U platform</b></h2>
                      <h4>We provide you with</h4>
                    </div>
                    <div className='platform'>
                        <div className="card">
                            <img className="card-img-top" src={laboratory1} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={laboratory2} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={laboratory3} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={laboratory4} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
       </section>
       <Subscribe/>
    </>
  )
}
