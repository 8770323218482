import axios from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";
import i18n from "../../config/i18n";
import { token } from "../../utils/user";

export const applyCouponCodeService= async(data:any) =>{
    return  await axios.post(API_URL+V+"apply-coupon",data,{
        headers:{
            "X-localization":i18n.language,
            'Content-Type': 'multipart/form-data',
            'Accept': 'application/json',
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}


export const getOrderedProductsService= async() =>{
    return  await axios.get(API_URL+V+"ordered/products",{
        headers:{
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (error)=>{
            if (error.response) {
                 
                return error.response;
              
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
          }
      )
}

export const createOrderService = async(data:any) =>{
    return  await axios.post(API_URL+V+"order/store",data,{
        headers:{
            'Content-Type': 'multipart/Form',
            'Accept': 'application/json',
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}

export const getSelledProductService= async(id:string|undefined) =>{
    return  await axios.get(API_URL+V+id+"/selled-products",{
        headers:{
            "X-localization": i18n.language,
            "Authorization":"Bearer "+token()
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}