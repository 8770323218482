import React from 'react'
import right from "../images/right.png"

export const NumberingCoding = () => {
  return (
    <>
       <section className='numbering-coding-bg-title'>

       </section>
       <section className='my-5 numbering-roles'>
         <div className='container'>
             <div className='row'>
                <div className='col-8'>
                    <h2><b>Numbering and Coding Center Fruit and Vegetable Incubator </b></h2>
The role is to
                </div>
                 <div className='col-12'>
                     <ul>
                         <li><img src={right} />Provides global numbers using a global barcode called the Global Numbering System (GS1).</li>
                         <li><img src={right} />Description of products and a unique and clear definition of products.</li>
                         <li><img src={right} />Provide training and support</li>
                         <li><img src={right} />Providing solutions that guarantee and allow traceability of the product from the farm to the final consumer</li>
                         <li><img src={right} />It allows very accurate and inexpensive data capture.</li>
                         <li><img src={right} />Providing the required information at all points of the supply chain.</li>
                         <li><img src={right} />A trusted partner in everything related to the global coding of goods, services, assets, shipments and property.</li>
                         <li><img src={right} />Provides global numbers using a global barcode called the Global Numbering System (GS1).</li>
                     </ul>
                 </div>
             </div>
         </div>
       </section>
    </>
  )
}
