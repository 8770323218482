import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { userLoginService } from '../../../api/auth/AuthService';
import { ShowNotification } from '../../../components/Notification/ShowNotification';

export const Signin = () => {
    const { t } = useTranslation();
    const location = useLocation() as any;
    const  navigate = useNavigate();
    const [errEmail, setErrEmail] = useState<string>("");
    const [errPassword, setErrPassword] = useState<string>("");

    const handleSignin = (event:any) => {
        event.preventDefault();
        const fd = new FormData(event.target);

          userLoginService(fd).then((res)=>{
              if(res?.success === true)
              {
                var today = new Date();
                today.setHours(today.getHours() + 1);
         
                  const result = {
                      data :res.data,
                      expires_at: today.getTime(),
                      token: res.token 
                  }
                  localStorage.setItem("profile",JSON.stringify(result));
                
                  if(location.state!==null)
                    {  
                        navigate(location.state.prevPath);
                    }
                    navigate("/");
              }
              if(res?.status === 422)
              {
                  setErrEmail(res.data.errors.email);
                  setErrPassword(res.data.errors.password);
              }

              if(res?.status === 401)
              {
                  console.log(res);
                  ShowNotification(res.data.message,"error")
              }
         });

    }
  return (
  <>
    <div className="container my-5">
        <div className="row justify-content-center">
            <div className="col-md-6 col-lg-5 col-sm-7"  style={{boxShadow: "0px 5px 15px 0px #00000040", borderRadius:"10px"}}>
                <div className="p-5">
                    <h5 className="text-center"><b>{ t('login_form_title') }</b></h5>
                    <div className="card-body py-5">
                        <form onSubmit={handleSignin}>    
                        <div className="form-group">
                                <input  type="email" className="form-control" name="email"  autoComplete="email" placeholder='Email'/>
                                <span className="invalid-feedback" role="alert">
                                    <strong>{errEmail}</strong>
                                </span>
                            </div> 

                            <div className="form-group">
                                <input type="password" className="form-control" name="password"  placeholder="Password"/>
                                <span className="invalid-feedback" role="alert">
                                    <strong >{errPassword}</strong>
                                </span>
                            </div>


                            <div className="form-group  mb-0">       
                                <button type="submit" className="btn btn-primary text-white w-100">
                                    { t('login') }
                                </button> <br/>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
  </>)
};
