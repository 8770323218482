import axios  from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";
import i18n from "../../config/i18n";

export const getCategoriesService= async() =>{
    return  await axios.get(API_URL+V+"categories",{
        headers:{
            "X-localization": i18n.language
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}

export const getCategoriesWithSubCategoriesService= async() =>{
    return  await axios.get(API_URL+V+"categories-with-subcategories",{
        headers:{
            "X-localization":"en"
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}

