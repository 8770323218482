import React, { useEffect, useState } from 'react';
import { EventSolution } from '../components/event/EventSolution';
import { Event } from '../models/Event';
import {   getEventSolutionService } from '../api/eventsolution/EventSolutionService';
import { Link, useParams } from 'react-router-dom';
import i18n from '../config/i18n';
import { useTranslation } from 'react-i18next';
import { CurrenciesContext } from '../components/context/CurrencyContext';
import { Slides } from '../components/slide/Slides';
import { getAboutUsService } from '../api/about-us/AboutUsService';
import { URL } from '../components/base/BaseUrl';
import { getBestOfferProductsService, getExibitionEquipmentGalleryService, getNewArrivalProductsService } from '../api/products/ProductService';
import { ShowNotification } from '../components/Notification/ShowNotification';
import { useCart } from 'react-use-cart';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Product } from '../components/products/Product';
import { SlideProduct } from '../components/products/SlideProduct';
import product1  from "../images/Homepage/shop/image--01.png";
import product2  from "../images/Homepage/shop/image--02.png";
import product3  from "../images/Homepage/shop/image--03.png";
import product4  from "../images/Homepage/shop/image--04.png";
import product5  from "../images/Homepage/shop/image--05.png";
import product6  from "../images/Homepage/shop/image--06.png";
import product7  from "../images/Homepage/shop/image--07.png";

import buyProduct from "../images/Homepage/buyProduct.png";
import saleCrop from "../images/Homepage/saleCrop.png"

import  sale  from "../images/sale.png"
import  stock_exchange  from "../images/stock_exchange.png"
import  connect_farmers  from "../images/connect_farmers.png"
import  selling  from "../images/selling.png"
import  clock  from "../images/clock.png"
import  hour  from "../images/24hour.png"
import  transportation  from "../images/transportation.png"

import { WhoWeAre } from '../components/WhoWeAre';
import { Subscribe } from '../components/Subscribe';

export default function Home() {

  const { t } = useTranslation();
  const [about,setAbout] = useState<any>({});
  const [solutions, setSolutions] = useState<Event[]>([]);
  const [bestProds, setBestProds] = useState([])
  const [newProds, setNewProds] = useState([]);
  const { addItem } = useCart();

  const products = [{
    "id":"1",
    "name":"Fold Carton packaging box",
    "photo":product1,
    "price":"12"
},
{
    "id":"2",
    "name":"Fold Carton packaging box",
    "photo":product2,
    "price":"12"
},
{
    "id":"3",
    "name":"Fold Carton packaging box",
    "photo":product3,
    "price":"12"
},
{
    "id":"4",
    "name":"Fold Carton packaging box",
    "photo":product4,
    "price":"12"
},
{
    "id":"5",
    "name":"Fold Carton packaging box",
    "photo":product5,
    "price":"17"
},
{
    "id":"6",
    "name":"Fold Carton packaging box",
    "photo":product6,
    "price":"12"
},
{
    "id":"7",
    "name":"Fold Carton packaging box",
    "photo":product7,
    "price":"12"
}]

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 6,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 600 },
      items: 4,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

   useEffect(()=>{
      fetchAboutUs();
      fetchEventSolutions();
      fetchNewArrivalProds();
      fetchBestOfferProducts();
  
   },[i18n.language]);

    const fetchAboutUs  = async () =>  {
       const res = await getAboutUsService();
       if(res.success === true)
       {
           setAbout(res.data);
       }
   }
   const fetchNewArrivalProds = async () => {
    const res = await getNewArrivalProductsService();
    if(res?.success === true)
    {
        setNewProds(res.data);
    }
}

const fetchBestOfferProducts = async () => {
  const res = await getBestOfferProductsService();
  if(res?.success === true)
  {
      setBestProds(res.data);
  }
}


   async function fetchEventSolutions() 
   {
       const res = await getEventSolutionService();
       if( typeof(res)!=="undefined" && res.success===true)
       {
          setSolutions(res.data);
       }
   }

   const addProductToCart = (product:any) =>{
    if(product.offer_price!=null && product.offer_price>=1){
       product.price= product.offer_price;
    } 
   addItem(product);
   ShowNotification("Product added in Cart","success");
}


  return (
     <>
       <Slides/>

          {/*  new arrivals  products*/}

          <section className="category-area p-spacer" dir='ltr'>
                <div>
                   <hr/>
                    <h5 className='text-center'>Vegetable & Fruits</h5>
                    <hr/>
                </div>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                    {products.map((product:any)=>(
                    <SlideProduct product={product} addProductToCart={addProductToCart} key={product.id}/>
                ))}
                </Carousel>
        </section>
        {/*  new arrtival products */}
    
      
         {/* best offers product*/}

         <section className="category-area p-spacer" dir='ltr'>
                <div>
                   <hr/>
                    <h5 className='text-center'>{ t("best_offers") }</h5>
                    <hr/>
                </div>
                <Carousel
                  swipeable={false}
                  draggable={false}
                  showDots={false}
                  responsive={responsive}
                  ssr={true} // means to render carousel on server-side.
                  infinite={true}
                  autoPlay={true}
                  autoPlaySpeed={1000}
                  keyBoardControl={true}
                  customTransition="transform 300ms ease-in-out"
                  transitionDuration={500}
                  containerClass="carousel-container"
                  removeArrowOnDeviceType={["tablet", "mobile"]}
                  
                  dotListClass="custom-dot-list-style"
                  itemClass="carousel-item-padding-40-px"
                >
                    {products.map((product:any)=>(
                    <SlideProduct product={product} addProductToCart={addProductToCart} key={product.id}/>
                ))}
                   </Carousel>
                
        </section>

        <section className='my-5'>
          <div className='container'>
              <div className='row'>
                 <div className='container'>
                      <div className='col-12'>
                      <div className='col-md-6 offset-md-2 my-5'>
                      <h1>Fruit and Vegetable Incubator online market</h1>
                  </div>
                      </div>
                 </div>
              <div className='col-sm-4 text-center'>
                  <img src={connect_farmers} className="img"/>
                  <p>To Connect Farmers And Traders</p>
              </div>
              <div className='col-sm-4 text-center'>
                  <img src={sale} className="img"/>
                  <p>To Facilitate, Market and Sell Products</p>
              </div>
              <div className='col-sm-4 text-center'>
                  <img src={stock_exchange} className="img"/>
                  <p>A Stock Exchange Platform and Reference</p>
              </div>
              </div>
          </div>
        </section>

         <div className='my-5'></div>
          <WhoWeAre/>

          <section className='my-5'>
            <div className='container'>
               <div className='row'>
                <div className='col-12 my-5 text-center'>
                    <h1>Fruit and Vegetable Incubator Provides You with</h1>
                </div>
                <div className='col-sm-6 col-md-3 text-center'>
                    <img src={selling} className="img-fluid"/>
                      <p>Ease of selling the crop at the best price</p>
                </div>

                <div className='col-sm-6 col-md-3 text-center'>
                    <img src={clock} className="img-fluid"/>
                      <p>24 hours customer service to answer any questions</p>
                </div>

                <div className='col-sm-6 col-md-3 text-center'>
                    <img src={hour} className="img-fluid"/>
                      <p>Save time and effort for farmer and trader</p>
                </div>
                <div className='col-sm-6 col-md-3 text-center'>
                    <img src={transportation} className="img-fluid"/>
                      <p>Crop packaging, transportation and storage service</p>
                </div>
                </div>
            </div>
          </section>

          <section className='buy-sell-banner my-5'>
              <div className='container'>
                  <div className='row'>
                       <div className='col-sm-6'>
                           <div className='buy-product'>
                                <h4 className='text-secondary'>Want To Become trader?</h4>
                                <h2 className='text-secondary'><b>Sell All Types of crops, Fruits & Vegetables in here</b></h2>
                                <Link to="signup-seller" className='btn btn-secondary'>Register</Link>
                           </div>
                       </div>

                       <div className='col-sm-6'>
                           <div className='buy-product'>
                                <h4 className='text-dark'>Looking for traders?</h4>
                                <h2 className='text-dark'><b>Sale your crop with the best price</b></h2>
                                <Link to="signup-user" className='btn btn-secondary'>Register</Link>
                           </div>
                       </div>
                  </div>
              </div>
          </section>

          <Subscribe/>
       
     </>
  )
}
