
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getProductDetailsService } from '../api/products/ProductService';
import { URL } from '../components/base/BaseUrl';
import { CurrenciesContext } from '../components/context/CurrencyContext';
import { Subscribe } from '../components/Subscribe';
import i18n from '../config/i18n';
import { useTranslation } from 'react-i18next';
import { Fade } from 'react-slideshow-image';
import { useCart } from 'react-use-cart';
import { ShowNotification } from '../components/Notification/ShowNotification';

export const ProductDetails = () => {
    const { t } = useTranslation();
    const { addItem } = useCart();
    const { id } = useParams();
    const [details, setProductDetails] = useState<any>({});
    const [images, setImages] = useState([]);
    useEffect(()=>{
        fetchProductDetails();
    },[i18n.language]);
    const fetchProductDetails = async () =>{
        const res = await getProductDetailsService(id);
        if(res?.success=== true)
        {
           
            setProductDetails(res.data);
            setImages(res.data.product_images);
        }
    }

    //. product added in the cart

    const addProductToCart = (product:any) =>{
        if(product.offer_price!=null && product.offer_price>=1){
           product.price= product.offer_price;
        } 

        product.product_images = images;
       addItem(product);
       ShowNotification("Product added in Cart","success");
   }
  return (
    <>
    <section className="product-breadcrumb-area mt-5">
            <div className="container">
                <nav aria-label="breadcrumb" className="product-breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="#">{ t("home") }</a></li>
                        <li className="breadcrumb-item active" aria-current="page">{t("product_des") }</li>
                    </ol>
                </nav>
            </div>
        </section>

        <section className="product-details-area">
            <div className="container">
                <div className="details-block">
                    <div className="row">
                        <div className="col-md-4">
                        <Fade>
                        {images?.map((fadeImage:any, index:number) => (
                            <div className="each-fade" key={index}>
                            <div className="image-container">
                                <img src={URL+fadeImage.photo} alt="" />
                            </div>
                            </div>
                        ))}
                        </Fade>
                        </div>
                        <div className="col-md-8">
                            <div className="product-details pl-3">
                               <h4>{details.name}</h4>
                                <div className="brand">
                                <strong>{ t("brand")}</strong>'
                                <span className="mx-1"></span>
                                <span>{details?.brand?.name}</span>
                                 </div>
                                <div className="short-desc mt-3">
                                <div><strong>{ t("product_des")}</strong></div> <hr/>
                                 <p>{ details.description}</p>
                               </div>
                              <div className="brand mb-3">'
                                <strong>{ t("price") }</strong>
                                 <span className="mx-1"></span>
                                <CurrenciesContext.Consumer>
                                    {({symbol,exchangeRate}) => (
                                        <span>{symbol}{details?.price*exchangeRate}</span> 
                                    )}
                                    </CurrenciesContext.Consumer>
                              </div>
                               <button onClick={(e:any) => addProductToCart(details)} className="btn btn-outline-primary mt-4 px-5">{ t("add_to_cart")}
                               </button>
                                <div className="share-on-social mt-3">
                               <div><strong>{ t("share_social") }</strong></div><hr/><div className="share-media"><a href=""><i className="fab fa-facebook-f"></i></a><a href=""><i className="fab fa-linkedin-in"></i></a><a href=""><i className="fab fa-instagram"></i></a> <a href=""><i className="fab fa-pinterest-p"></i></a></div></div>'
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      

       <Subscribe/>

    </>
  )
  ;
};
