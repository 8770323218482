import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { useCart,  } from 'react-use-cart';
import { applyCouponCodeService } from '../api/order/Order';
import { URL } from '../components/base/BaseUrl';
import { CurrenciesContext, Currency } from '../components/context/CurrencyContext';
import { ShowNotification } from '../components/Notification/ShowNotification';

export const Cart = () => {
    const { t } = useTranslation();
     const navigate = useNavigate();
     const { items, updateItemQuantity } = useCart();
     const [total, setTotal ] = useState(0);
     const [discount, setDiscount] = useState<number>(0);
     const [coupon_code, setCouponCode ] = useState<string>("");

     useEffect (()=>{
        calCulateTotal();
     },[items]);

     const calCulateTotal = () =>{
         let sum = 0 as any;
         items.map((item)=>{
            sum += (item.price*(item?.quantity?item?.quantity:0)); 
         });

         setTotal(sum);
     }

     const increaseQuantity = (e:any,id:string,qnty:number) =>{
         e.preventDefault();
        updateItemQuantity(id,qnty);
        ShowNotification("Product quantity updated","success");
     }

     const decreaseQuantity = (e:any,id:string,qnty:number) =>{
        e.preventDefault();
       updateItemQuantity(id,qnty);
       ShowNotification("Product quantity updated","success");
    }

    const applyCouponCode = (e:any) =>{
        e.preventDefault();
        const data = {
            code:coupon_code,
            total:total
        }
          applyCouponCodeService(data).then((res)=>{
            if(res?.success === true)
            {
               setDiscount(res.data.discount);
            }
            if(res?.status === 422)
            {
                   ShowNotification(res.message,"success");
            }
          })
    }

    const checkoutPage = () =>{
        navigate("/checkout",{  state:{
            total: total,
            discount:discount,
            coupon_code:coupon_code
        }
    })

    }
    
  return (
  <>
  <section className="cart-details my-5">
        <div className="container">
            <div className="row">
                <div className="col-12 text-center">
                    <p>{ t("cart_details")} </p>
                </div>
                <div className="col-12 text-center">
                  <form >
                    <table className="table table-responsive">
                        <thead>
                          <tr >
                            <th scope="col">S.N</th>
                            <th scope="col">{ t('product') }</th>
                            <th scope="col">{ t('image') }</th>
                            <th scope="col">{ t('price') }</th>
                            <th scope="col">{ t('qty') }</th>
                            <th  scope="col">{ t('sub_total') }</th>
                          </tr>
                        </thead>
                        <tbody className="cart-product">
                        { items.map((item:any, index:number)=>(
                            <tr key={item.id}>
                                <td>{index+1}</td>
                                <td>{item.name}</td>
                                <td><img src={URL+item.product_images[0].photo} alt="" style={{height:"40px"}}/></td>
                                <td>
                                    <CurrenciesContext.Consumer>
                                    {({symbol,exchangeRate}) => (
                                        <>
                                        <span >{symbol}{item.price*exchangeRate}</span> 
                                        
                                        </>
                                    )}
                                    </CurrenciesContext.Consumer>
                                          
                                </td>
                                <td  className="text-center"> <button className='btn btn-primary' onClick={e=>increaseQuantity(e,item.id,item.quantity+1)}><i className='fas fa-plus'></i></button> <span className='h4'>{item.quantity}</span><button className='btn btn-primary mx-2'onClick={e=>decreaseQuantity(e,item.id,item.quantity-1)}><i className='fas fa-minus'></i></button></td>
                                 <td>  <CurrenciesContext.Consumer>
                                    {({symbol,exchangeRate}) => (
                                        <>
                                        <span >{symbol}{item.price*exchangeRate*item.quantity}</span> 
                                        </>
                                    )}
                                    </CurrenciesContext.Consumer>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                      </table>
                    </form>
                </div>
                <div className="col-12 offset-md-8  col-md-4">
                   <div className="cart-total">
                   <div className="d-flex justify-content-between">
                            <div>{ t('total') }</div>
                            <div> 
                                    <CurrenciesContext.Consumer>
                                    {({symbol,exchangeRate}) => (
                                        <>
                                        <span >{symbol}{total*exchangeRate}</span> 
                                        
                                        </>
                                    )}
                                    </CurrenciesContext.Consumer>
                            </div>
                        </div>
                       <div className="d-flex justify-content-between">
                           <div>{ t('discount') } </div>
                           <div>{discount}</div>
                       </div>

                       <div className="d-flex justify-content-between">
                           <div>{ t('total_pay') }</div>
                           <div className="total-payable">{(total-discount)}</div>
                       </div>
                       
                   </div>
                </div>
            </div>
        </div>
    </section>


    <section className="coupon-area">
        <div className="container">
            <div className="row">
                <div className="col-12 d-flex coupon-cart">
                    <div className="coupon-code">
                        <form className="form-inline" onSubmit={applyCouponCode} method="post">
                            
                            <div className="form-group mx-sm-3 mb-2">
                              <input type="text"  onChange={e=>setCouponCode(e.target.value)} placeholder="Coupon Code"/>
                              <button type="submit" className="cpn-btn ">{ t('apply_coupon') }</button>
                            </div>
                           
                          </form>
                    </div>
                </div>
            </div>
        </div>
    </section>


    <section className="cart-total-area">
        <div className="container">
            <div className="row">
                  <div className="col-12">
                    <div className="d-flex justify-space-btwn ">
                        <Link to="/" className="custom-btn btn-primary text-white">{ t('back_to_home') }</Link>
                        <button onClick={e=> checkoutPage()}  className="custom-btn btn-primary text-white checkout" >{ t('process_checkout') }</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
  );
}
