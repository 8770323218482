import axios from "axios";
import { API_URL, V } from "../components/base/BaseUrl";
import i18n from "../config/i18n";


export const getLatestService= async() =>{
    return  await axios.get(API_URL+V+"latest-service",{
        headers:{
            "X-localization": i18n.language,
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}

export const getServices= async() =>{
    return  await axios.get(API_URL+V+"services",{
        headers:{
            "X-localization":i18n.language,
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}