import axios from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";
import i18n from "../../config/i18n";

export const getAboutUsServicesList= async() =>{
    return  await axios.get(API_URL+V+"about-us/services",{
        headers:{
            "X-localization": i18n.language
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}