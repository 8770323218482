import React from 'react'
import { Subscribe } from '../components/Subscribe'
import provide1 from "../images/warehouse/platform/icated-places-for-cooling-and-freezing.png"
import provide2 from "../images/warehouse/platform/Places-dedicated-to-keeping-crops.png"
import provide3 from "../images/warehouse/platform/Places-for-storing-short-term-crops.png"
import provide4 from "../images/warehouse/platform/Providing-places-for-transport-vehicles-and-storage-tools.png"

export const Warehouse = () => {
  return (
    <>
       <section className='warehouse-bg-title'>

       </section>
       <section className='my-5'>
           <div className='container'>
               <div className='row'>
                    <div className='col-12'>
                    <h2><b>In cooperation with the Aria4U platform</b></h2>
                      <h4>We provide you with</h4>
                    </div>
                    <div className='platform'>
                        <div className="card">
                            <img className="card-img-top" src={provide1} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={provide2} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={provide3} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                        <div className="card">
                            <img className="card-img-top" src={provide4} alt="Card  cap"/>
                            <div className="card-body"> 
                                <p className="card-text">Providing places for transport vehicles and storage tools</p>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
       </section>
       <Subscribe/>
    </>
  )
}
