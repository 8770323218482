import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom'
import { useCart } from 'react-use-cart';
import i18n from '../../config/i18n';
import logo from "../../images/logo.png"
import { CurrenciesContext } from '../context/CurrencyContext';
import { ProductSearchBar } from './ProductSearchBar';


export const DesktopTopBar = () => {
    const { t } = useTranslation()

    const {totalUniqueItems } = useCart()
    
    let user:any = localStorage.getItem("profile");
  if(user && user!= "")
  {
      user = JSON.parse(user);
  }

 


    const changeLanguage = (name:string) => {
        i18n.changeLanguage(name);
        document.body.dir = i18n.dir();
     
        let arabic = document.getElementById("arabicCss");

        if(i18n.dir()==="rtl"){
          if(arabic){
             arabic.setAttribute("href","css/arabic.css?v="+new Date().getTime());
          }
        }
        else{
          if(arabic)
          arabic.setAttribute("href","");
        }
       
    }


  return (
    <section className='desktop-topbar'>
    <div className="topbar-area" >
    <div className="container">
      <div className="row">
        <div className="col-12 topbar">
          <div className="topbar-left">
            <div className="help-center">
                <i className="fas fa-headphones-alt"></i>
                <span className="mx-1"> { t("hotline")}: </span>
                <span className="font-sans">+966 551175959</span>
            </div>
          </div>
          <div className="topbar-right">
            <div className="topbar-menu">
                <div className="btn-group">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span> { t("language") }</span>
                      <i className="fas fa-angle-down"></i>
                    </span>
                    
                    <div className="dropdown-menu dropdown-menu-right">
                      <button  className="dropdown-item"  onClick={e=>changeLanguage("en")} > {(i18n.language==="en")?(<i className="fas fa-check language-active"></i>):(<i className="fas fa-check"></i>)}<span>{ t("english")} </span>  </button>
                      <button  className="dropdown-item"  onClick={e=>changeLanguage("ar")}> {(i18n.language==="ar")?(<i className="fas fa-check language-active"></i>):(<i className="fas fa-check"></i>)}{ t("arabic")}  </button>
                    </div>
                </div>
                <div className="btn-group currency-dropdown">
                    <span data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <span>SignUp</span>
                      <i className="fas fa-angle-down"></i>
                    </span>
                    <div className="dropdown-menu dropdown-menu-right ">
          
                          <Link to="signup-user"  className="dropdown-item" >
                            <i className="fas fa-check language-active"></i>
                              <span>User</span>
                          </Link>

                          <Link to="signup-seller"  className="dropdown-item" >
                            <i className="fas fa-check language-active"></i>
                              <span>Vendor</span>
                          </Link>     
                    </div>
                </div>
               
                {(user && user.data.user_type==3)?( <div className='btn-group'> <Link to="/client-panel" className=" w-100" >{ t("dashbaord")} </Link>  
                    </div>):""}
                 {(user && user.data.user_type==2)?(
                <div className='btn-group'> <Link to="/seller" className=" w-100" >{ t("dashbaord")} </Link> 
                 </div>
                 ):""} 

                    {(!user)?  (
                      <>
                    <div className='btn-group'> <Link to="/signin-user" className=" w-100" >{ t("signin")} </Link>  
                    </div>
               
                </>
                    ):""}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="navbar-area">
    <div className="container">
        <div className="row">
            <div className="col">
                <div className="navbar-content">
                    <div className="logo">
                        <a href="/">
                          <img width="180" src={logo} alt="Site Logo"/>
                        </a>
                    </div>
                    <ProductSearchBar/>
                    <div className="navbar-menus">
                      
                        <Link to="/cart" className="mx-3">
                            <i className="fas fa-cart-arrow-down"></i>
                            <span className="cartItem font-sans">{totalUniqueItems}</span>
                        </Link>
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  </section>
  )
}
