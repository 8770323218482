import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ClientPanelLayout } from '../components/layouts/ClientPanelLayout';
import Layout from '../components/layouts/Layout';
import { Dashboard } from '../pages/Client-panel/Dashboard';
import { OrderedProduct } from '../pages/Client-panel/OrderedProduct';
import { ProfileUpdate } from '../pages/Client-panel/ProfileUpdate';

export const ClientPanelRoute = () => {
    return(
    <ClientPanelLayout>
        <Routes>
             <Route  path="/"  element={<Dashboard/>}/>
             <Route path="/profile/update"  element={<ProfileUpdate/>}/>
             <Route path="/ordered-products"  element={<OrderedProduct/>}/>
        </Routes>
    </ClientPanelLayout>
    )
};
