import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export const SelledProduct = () => {
    const [products, setProducts] = useState([]);
    
    return (
        <div className="container">
        <div className="row">
            <div className='col-12 d-flex justify-content-between'>
                <h2>Selled Products</h2>
            </div>
            <div className="col-12 ">
                <table className="table table-responsive table-bordered">
                    <thead>
                        <tr>
                            <th>SN</th>
                            <th>Order Id </th>
                            <th>Payment Type </th>
                            <th>Payment Status </th>
                            <th>Total </th>
                            <th>Discount </th>
                            <th>Shipping Cost</th>
                            <th>Grand Total </th>
                            <th>Delivery Status</th>
                            <th>Currency</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                      <tbody>
                        {products.map((order:any,index:number)=>(
                             <tr>
                                  <td>{index+1}</td>
                                  <td>{ order.id }</td>
                                    <td>{ order?.payment_type }</td>
                                    <td>{(order.payment_status===0)? "Unpaid" : "Paid" }</td>
                                    <td>{ order.total }</td>
                                    <td>{order.coupon_discount } </td>
                                    <td>{ order.shipping_cost} </td>
                                    <td>{order.total+order.shipping_cost-order.coupon_discount}</td>
                                    <td>{(order.delivery_status==0)? "Not Delivered": "delivered"}</td>
                                      <td>{ order.currency }</td>
                                     <td>{(order.status==0)? "Not Completed" : "Completed" } </td>
                                       
                             </tr>
                        ))}
                         
                      </tbody>
                </table>
            </div>
        </div>
        </div>
    )
};
