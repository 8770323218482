import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useCart } from 'react-use-cart';
import { getProductByCategoryService } from '../api/products/ProductService';
import { ShowNotification } from '../components/Notification/ShowNotification';
import { Product } from '../components/products/Product';
import i18n from '../config/i18n';

export const ProductByCategory = () => {
  const { t } = useTranslation();
    const { addItem } = useCart()
    const {categoryId} = useParams();

    const [productts, setProducts] = useState([]);
    useEffect( ()=>{
      fetchProductByCategoryId();
    },[i18n.language])

    const fetchProductByCategoryId = async()=>{
        const res = await getProductByCategoryService(categoryId);
        if(res?.success)
        {
            setProducts(res.data);
        }
    }

    const addProductToCart = (product:any) =>{
      addItem(product);
      ShowNotification("Product added in Cart","success");
  }
  return  (
  <section className="featured-product-section p-spacer">
    <div className="container">
      <h5>{ t('product')}</h5>
      <hr/>
      <div className="row">
          {productts.map((product:any)=>{
               <Product product={product} addProductToCart={addProductToCart}/>
          })}
  
      </div>
    </div>
</section>)
};
