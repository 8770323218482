
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { URL } from '../base/BaseUrl';
import { CurrenciesContext } from '../context/CurrencyContext';


export const SlideProduct : React.FC<any> = ({product, addProductToCart}) => {
  const { t } = useTranslation();
  return ( 
        <>
           <section className='slide-product' >
            <div className="product-image">
            <Link to={`/product-details/${product.id}/${product.name.replace(" ", "_")}`}>
            <img src={product.photo} alt="" className="img-fluid"/>
            </Link>
            </div>
                <div className="product-info px-2">
                <h3>{product.name}</h3>
                <div className="price-and-ratings font-sans">
                    <div className="product-price">
                    <CurrenciesContext.Consumer>
                        {({symbol,exchangeRate}) => (
                            <span>{symbol}{product.price*exchangeRate}</span> 
                        )}
                        </CurrenciesContext.Consumer>
               
                </div> <div className="product-rating"><i className="fas fa-star"></i><i className="fas fa-star"></i><i className="fas fa-star"></i> <i className="fas fa-star"></i><i className="fas fa-star"></i> <span className="mx-1"></span> <span>(35)</span></div>
                </div> <button onClick={(e)=>{e.preventDefault();addProductToCart(product)} }  className="buy-now text-white  btn ">{ t("add_to_cart") }</button> </div>
                </section>
        </>
  );
}
