 import React from 'react'
import { Subscribe } from '../components/Subscribe'
 
 export const Logistic = () => {
   return (
     <>
        <section className='logistic-bg-title'>

        </section>

        <section>
            <div className='container my-5'>
                <div className='row'>
                    <div className='col-12 col-sm-8'>
                        <h2><b>In cooperation with the Tranco platform and Mokhlis we do</b></h2>
                    </div>
                </div>
            </div>
        </section>

        <section className='logistic-service-area'>
            <div className='container'>
              <div className='logistic-service'>
                    <div className='service-box'>
                        <h6>Logistic Service</h6>
                        <p>Providing trucks licensed to transport crops</p>
                     </div>
                     <div className='service-box'>
                        <h6>Logistic Service</h6>
                        <p>Providing trucks with refrigerators for refrigerated transport</p>
                     </div>
                     <div className='service-box'>
                        <h6>Logistic Service</h6>
                        <p>Facilitating the purchase or leasing of trucks according to the purpose</p>
                     </div>
                </div>
              </div>
        </section>

        <section className='my-5'>
            <div className='container'>
                <div className='row'>
                     <div className='col-sm-7'>
                          <h2><b>In cooperation with the Greenco platform</b></h2>
                     </div>
                     <div className='col-sm-6'>
                         <ul>
                             <li>Providing all kinds of agricultural seeds</li>
                             <li>Providing all kinds of agricultural fertilizers</li>
                         </ul>
                     </div>
                </div>
            </div>
        </section>

        <Subscribe/>
     </>
   )
 }
 