import axios from "axios";
import { API_URL, V } from "../../components/base/BaseUrl";
import i18n from "../../config/i18n";
import { token } from "../../utils/user";

export const getEventSolutionService= async() =>{
    return  await axios.get(API_URL+V+"event-types",{
        headers:{
            "X-localization": i18n.language
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}


export const getEventSolutionCategoriesService= async() =>{
    return  await axios.get(API_URL+V+"event-categories",{
        headers:{
            "X-localization": i18n.language
        }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (err)=>{
              console.log(err.toJSON());
          }
      )
}

export const storeEventSolutionRequestService= async(data:any) =>{
    return  await axios.post(API_URL+V+"event-type-request",data,{
            headers:{
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Type',
            }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (error)=>{
            if (error.response) {
                   
                return error.response;
              
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
            }
      )
}

export const storeEventCategoryRequestService= async(data:any) =>{
    return  await axios.post(API_URL+V+"event-category-request",data,{
            headers:{
                'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'Access-Control-Allow-Headers': 'Content-Type',
            }
    }).then(
          (res)=>{
              return res.data;
          }
      ).catch(
          (error)=>{
            if (error.response) {
                   
                return error.response;
              
              } else if (error.request) {
                console.log(error.request);
              } else {
                console.log('Error', error.message);
              }
            }
      )
}



