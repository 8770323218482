import React from 'react';
import { useTranslation } from 'react-i18next';

export const TermCondition = () => {
    const { t } = useTranslation();
  return (
  <>
   <section className="term-top">
            <div className="container">
                <div className="row">
                    <div className="col-12 ">
                      <div className="title">
                         <h2 className="text-warning text-center">{ t('term_use') }</h2>
                      </div>
                    </div>
                  </div>
            </div>
        </section>


     <section className="term-indroduction my-5">
         <div className="container">
             <div className="row">
                 <div className="col-12">
                     <h3 className="text-center">{ t('introduction')  }</h3>
                     <p>{ t('introduction_details') }</p>
                 </div>
             </div>
        </div>
     </section>

      <section className="term-article-area">
          <div className="container">
              <div className="row">
                  <div className="col-12">
                      <h3>{ t('article_one') }</h3>
                      <h6>{ t('article_one_above' ) }:</h6>
                      <ul>
                   
                          <li>{ t('article_one_seller') }</li>
                          <li>{ t('article_one_buyer') }</li>
                          <li>{ t('article_one_equipment')}</li>
                          <li>{ t('term.article_one_sale') }</li>
                      </ul>
                  </div>
                  
                   <div className="col-12 my-3">
                      <h3>{ t('article_two') }</h3>
                      
                        <p>{ t('article_two_details') }</p>
                  </div>
                  
                    <div className="col-12 my-3">
                      <h3>{ t('article_three')}</h3>
                      <h6>{ t('article_three_mission')}:</h6>
                      <ul>
                          <li>{ t('article_three_mission')}</li>
                          <li>{ t('article_three_provide') }</li>
                          <li>{ t("article_three_play") }
                              <p>{ t('article_three_rental_fee') }</p>
                              <p>{ t('article_three_other_charge') }
                                   <ul>
                                       <li>{ t('article_three_cost_owner')}</li>
                                       <li>{ t('article_three_cost_equipment') }</li>
                                       <li>{ t('article_three_other_expense') }</li>
                                       <li>{ t('article_three_vat') }</li>
                                       <li>{ t('article_three_event') }</li>
                                   </ul>
                              </p>
                          </li>
                          
                      </ul>
                  </div>
                  
                  <div className="col-12 my-3">
                      <h3>{ t('article_four')}</h3>
                      
                        <p>{ t('article_four_details') }</p>
                  </div>
                  
                  <div className="col-12 my-3">
                      <h3>{t('article_5')}</h3>
                        <p>{ t('article_5_details')}</p>
                  </div>
                  
                   <div className="col-12 my-3">
                      <h3>{ t('article_6')}</h3>
                        <p>{ t('article_6_equipment')}</p>
                        <p>{ t('article_6_buyer') }</p>
                  </div>
                  
                   <div className="col-12 my-3">
                      <h3>{ t('article_7') }</h3>
                        <p>{ t('article_7_details') }</p>
                  </div>
                  
                  <div className="col-12 my-3">
                      <h3>{ t('article_8') }</h3>
                      <p>{ t('article_8_details') }</p>
                  </div>
                  
                  <div className="col-12 my-3">
                      <h3>{  t('article_9') }</h3>
                      <p> { t('article_9_a') }   </p>
                          <ul>
                              <li>{ t('article_9_equipment') }</li>
                              <li>{ t('article_9_according') }</li>
                              <li>{ t('article_9_Lessee') }</li>
                          </ul>
                   
                      <p>{ t('article_9_b') }</p>
                  </div>
                  
                  <div className="col-12 my-3">
                      <h3>{ t('article_10') }</h3>
                      <p>{ t('article_10_equipment') }</p>
                  </div>
                  
                  <div className="col-12 my-3">
                      <h3>{ t('article_11') }</h3>
                      <p>{ t('article_11_details') }</p>
                  </div>
                  
                    
                  <div className="col-12 my-3">
                      <h3>{t('article_12') }</h3>
                      <p>{ t('article_12_details') }</p>
                  </div>
                  
                    
                  <div className="col-12 my-3">
                      <h3>{ t('article_13') }</h3>
                      <p>{ t('article_13_details') }</p>
                  </div>
                  
                    
                  <div className="col-12 my-3">
                      <h3>{ t('article_14') }</h3>
                      <p>{ t('article_14_details') }</p>
                  </div>
                  
                  
                         
                  <div className="col-12 my-3">
                      <h3>{ t('article_15') }</h3>
                      <p>{ t('article_15_a')}</p>
                      <p>{ t('article_15_b') }</p>
                      <p>{ t('article_15_c') }</p>
                  </div>
                 
              </div>
          </div>
      </section>
  </>);
};
