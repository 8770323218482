import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const Order = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { state } = location as any

  return (
    <>

        <h1 className="alert alert-info order-id">Your order id number:  {state.orderId}</h1>
    </>
  );
};
