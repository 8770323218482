import React from 'react';

export const Spinner = () => {
  return (
     <div className='container'>
         <div className='row'>
             <div className='col-12 d-flex align-items-center justify-content-center vh-100'>
                <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
             </div>
         </div>
     </div>
  
  );
};
