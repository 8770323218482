import React, { useEffect, useState } from 'react';
import { getOrderedProductsService } from '../../api/order/Order';
import  "./OrderedProduct.css"
export const OrderedProduct = () => {
   const [products , setProducts] = useState([]);
   useEffect(()=>{
    fetchOrderedProducts();
   },[])

   const  fetchOrderedProducts = async () =>{
        const res = await getOrderedProductsService();
        console.log(res);
        if(res?.success === true)
        {
            setProducts(res.data);
        }
   }
  return (
  <div className="container">
  <div className="row">
      <div className='col-12'>
          <h2 className='text-center'>Order List</h2>
      </div>
      <div className="col-12 ">
          <table className="table table-responsive table-bordered">
              <thead>
                  <tr>
                      <td>Order Id</td>
                      <td>Total</td>
                      <td>Shipping Cost</td>
                      <td>Discount</td>
                      <td>Total Payable</td>
                      <td>Payment Status </td>
                      <td>Currency</td>
                  </tr>
              </thead>
                <tbody>
                  {products.map((order:any,index:number)=>(
                       <tr>
                            <td>{order.id}</td>
                            <td>{order.total}</td>
                            <td>{order.shipping_cost}</td>
                            <td>{order.discount}</td>
                            <td>{order.total+order.shipping_cost-order.discount}</td>
                            <td>{(order.payment_status)? "Paid" : "Unpaid"}</td>
                            <td>{order.currency}</td>
                       </tr>
                  ))}
                   
                </tbody>
          </table>
      </div>
  </div>
  </div>
  )
};
