import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBrandsService } from '../../../api/Brand/BrandService';
import { getCategoriesService } from '../../../api/category/CategoeryService';
import { getProductTypesService } from '../../../api/product-type/ProductType';
import { storeProductService } from '../../../api/products/ProductCrud';
import { ShowNotification } from '../../../components/Notification/ShowNotification';
import i18n from '../../../config/i18n';

export const CreateProduct = () => {
  const navigate = useNavigate();
  const [cats, setCats ] = useState([]);
  const [brands, setBrands ] = useState([]);
  const [types, setTypes] = useState([]);
  const [errEnName , setErrEnName] = useState<string>("");
  const [errArName , setErrArName] = useState<string>("");
  const [errEnDescription , setErrEnDescription] = useState<string>("");
  const [errArDescription , setErrArDescription] = useState<string>("");
  const [errPhoto , setErrPhoto] = useState<string>("");

   useEffect(()=>{
    fetchCategories();
    fetchBrands();
    fetchProductTypes();
   },[i18n.language]);

    const  fetchCategories =  async () =>{
         const res = await getCategoriesService();
         if(res?.success===true)
         {
            setCats(res.data);
         }
    }
    const  fetchBrands =  async () =>{
      const res = await getBrandsService();
      if(res?.success===true)
      {
         setBrands(res.data);
          }
    }
    const  fetchProductTypes =  async () =>{
      const res = await getProductTypesService();
      if(res?.success===true)
      {
        setTypes(res.data);
      }
    }

  const handelProductStore = (e:any) =>{
      e.preventDefault();
      const data = new FormData(e.target);
      storeProductService(data).then((res)=>{
        console.log(res);
          if(res?.success === true)
          {
              ShowNotification("Product added success","success");
              navigate("/seller/product");
          }
          else{
              setErrEnName(res.data.errors.en_name);
              setErrArName(res.data.errors.ar_name);
              setErrEnDescription(res.data.errors.en_description);
              setErrArDescription(res.data.errors.ar_description);
              setErrPhoto(res.data.errors.photos)
          }
      });
  }
  return (
       <>
         <div className="container" >
            <div className="row">
            <form className='p-5 bg-white profile-update' encType='multipart/form-data' onSubmit={handelProductStore} style={{boxShadow:"5px 5px 5px #ffffff"}}>
                 <h2 className='text-center pb-3'>Product Create</h2>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label > Name(en)</label>
                      <input type="text" name="en_name" className="form-control"/>
                      <div className='invalid-feedback'>
                          <p>{errEnName}</p>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label > Name(ar)</label>
                      <input type="text" name="ar_name" className="form-control" />
                      <div className='invalid-feedback'>
                          <p>{errArName}</p>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Description(en)</label>
                       <textarea className='form-control' name="en_description" rows={3}>

                       </textarea>
                      <div className='invalid-feedback'>
                          <p>{errEnDescription}</p>
                      </div>
                    </div>

                    <div className="form-group col-md-6">
                      <label>Description(ar)</label>
                       <textarea name="ar_description" className='form-control' rows={3}>

                       </textarea>
                      <div className='invalid-feedback'>
                          <p>{errArDescription}</p>
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Category Name</label>
                        <select name="cat_id" className='form-control'>
                              {cats.map((cat:any)=>(
                                  <option  value={cat.id}>{cat.name}</option>
                               ))}
                        </select>
                    </div>

                    <div className="form-group col-md-6">
                      <label >Brand </label>
                       <select name="brand_id" className='form-control'>
                           {brands.map((brand:any)=>(
                              <option value={brand.id}>{brand.name}</option>
                           ))}
                            
                         </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Price</label>
                      <input type="number" min={1} className="form-control"  name="price"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label> Offer Price</label>
                      <input type="number" min={0} className="form-control"  name="offer_price"/>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Product Type</label>
                       <select name="product_type" className='form-control'>
                         {types.map((type:any)=>(
                             <option value={type.id}  className='form-control'>{type.name}</option>
                         ))}
                          
                       </select>
                    </div>
                    <div className="form-group col-md-6">
                      <label >Photo</label>
                      <input type="file" className="form-control"  name="photos[]"/>
                      <div className='invalid-feedback'>
                          <p>{errPhoto}</p>
                      </div>
                    </div>
                   
                  </div>
                  <div className="form-group">
                    <label >Product Status</label>
                      <select name="status" className='form-control'>
                         <option value="1">Enable</option>
                         <option value="0">Disable</option>
                      </select>
                  </div>
     
                  <button type="submit" className="btn btn-primary">Update </button>
                </form>
           </div>
          </div>
      </>
  )
};
