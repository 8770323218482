
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSettingsService } from '../../api/Address/Setting';
import i18n from '../../config/i18n';
import logo from "../../images/logo.png"


export const Footer = () => {
  const { t } = useTranslation();
  const [setting, setSetting] = useState<any>();
     useEffect(()=>{
        fetchSetting();
     },[i18n.language]);

    const  fetchSetting = async () =>{
        const res = await getSettingsService();
        if(res?.success === true)
        {
            setSetting(res.data);
        }
     }

    return (
    <footer>
      <section className="footer-menu-area p-spacer">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="address-section font-sans">
                <a href="/">
                  <img width="180" src={logo} alt="Site Logo"/>
                </a>
                <address>
                  <strong></strong><span>{setting?.address}</span>
                </address>
                <span className="d-block mb-2 phone" >{setting?.phone}</span>
                <span className="email">{setting?.email}</span>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer-mainmenu">
                <h6>{ t("main_menu") }</h6>
                <hr/>
                <ul>
                  <li><a href="/shop-now">{ t("shop_now")} </a></li>
                  <li><a href="/shop-now">{ t("trendy_product") }</a></li>
                  <li><a href="/investment-in-event">{ t('invest_event') } </a></li>
                  <li><a href="/media-center">{ t('media_center')} </a></li>
                 
                </ul>
              </div>
            </div>
            <div className="col-md-4 ">
              <div className="footer-important-link m-auto">
                <h6>{ t("important_link") }</h6>
                <hr/>
                <ul>
                  <li><a href="/our-services">{ t("our_services")} </a></li>
                  <li><a href="/contact-us">{ t("contact_us") }</a></li>
                  <li><a href="/term-condition">{ t("term_con") }</a></li>
               
                </ul>
              </div>
            </div>
           
          </div>
        </div>
      </section>
      <section className="copyright">
        <p>Copyright ©2020 All Rights Reserved at Eventincu.com</p>
      </section>
    </footer>
    )
};
